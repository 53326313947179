import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faSave, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import LoadingPage from "../../../../pages/LoadingPage";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import DefaultButton from "../../../tools/DefaultButton";
import moment from "moment";
import AddNorteadores from "../../../modules/pe/mapa/AddNorteadores";
import AddPerspectivas from "../../../modules/pe/mapa/AddPerspectivas";
import IntervalSelector from "../../../tools/IntervalSelector";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DiagramHelper from "../../../../helper/pe/DiagramHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import EssentialStyle from "../../../../style/EssentialStyle";
import CustomTooltip from "../../../tools/CustomTooltip";

export default class EditMapa extends React.Component {
    state = {
        loading: true,
        colabs: [],
        name: '',
        responsable: null,
        participants: [],
        perspectivas: [],
        norteadores: [],
        intervalos: [],
        visoes: [],
        data_inicio: null,
        data_fim: null,
        visao: null,
        width: 0,
        tiposProgresso: [],
        tipoProgresso: null,
        permissao_indicador: null,
        permissao_projeto: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const infoMapa = await Sig.request('GET', 'pe/mapa/getMapa', { id: this.props.id });

        const participants = infoMapa.participantes.map((participant) => { return { value: participant.id, label: participant.nome } });
        const perspectivas = infoMapa.perspectivas;
        const norteadores = infoMapa.norteadores;
        const mapa = infoMapa.mapa;
        const intervalos = infoMapa.intervalos;

        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];
        let visoes = (await Sig.request('GET', 'pe/visao/list', { relations: 0 }))?.visoes || [];
        let tiposProgresso = await Sig.request('GET', 'pe/mapa/getTiposProgresso', { mapa: this.props.id }) || [];

        if (mapa) {
            if (mapa.nome) this.setState({ name: mapa.nome });
            if (mapa.width) this.setState({ width: parseInt(mapa.width) });
            if (mapa.permissao_indicador) this.setState({ permissao_indicador: Number(mapa.permissao_indicador) });
            if (mapa.permissao_projeto) this.setState({ permissao_projeto: Number(mapa.permissao_projeto) });

            if (colabs && colabs.length) {
                let selectedParticipantsIds = participants.map(p => (p.id));
                let selectedParticipants = [];
                let responsable = null;

                colabs.forEach(participant => {
                    if (selectedParticipantsIds.includes(participant.id)) {
                        selectedParticipants.push(participant);
                    }
                    if (mapa.id_responsavel === participant.id) {
                        responsable = DataHelper.formatSelectData([participant], 'id', 'nome')[0];
                    }
                });

                this.setState({ responsable, participants: participants});
            }

            if (mapa.id_pe_visao) {

                let visao = visoes.find(item => item.id === mapa.id_pe_visao);

                if (visao) {

                    visao = DataHelper.formatSelectData([visao], 'id', 'nome')[0];

                    this.setState({ visao });
                }
            }

            if (intervalos.length) {

                let intervalosParsed = [];

                intervalos.forEach((intervalo, key) => {

                    intervalosParsed.push({ from: intervalo.minimo, to: intervalo.maximo, color: intervalo.cor, infinito: intervalo.infinito });
                });
                this.setState({ intervalos: intervalosParsed });
            }

            if (norteadores.length) {

                let norteadoresParsed = [];

                norteadores.forEach((norteador, key) => {

                    norteadoresParsed.push({ name: norteador.nome, content: norteador.content });
                });

                this.setState({ norteadores: norteadoresParsed });
            }

            if (perspectivas.length) {

                let perspectivasParsed = [];

                perspectivas.forEach((perspectiva, key) => {
                    perspectivasParsed.push({ name: perspectiva.perspectiva.nome, id: perspectiva.perspectiva.id });
                });

                this.setState({ perspectivas: perspectivasParsed });
            }
        }

        this.setState({ colabs, visoes, data_inicio: moment(mapa.data_inicio).format('YYYY-MM-DD'), data_fim: moment(mapa.data_fim).format('YYYY-MM-DD'), tipoProgresso: tiposProgresso.atual, tiposProgresso: tiposProgresso.tipos }, () => { this.setState({ loading: false }) });
    }

    intervalosIsValid() {
        let intervalos =[];
        let haveInfinito = false, response = true;

        for (let intervalo of this.state.intervalos) {

            intervalos.push(parseInt(intervalo.from));
            intervalos.push(intervalo.infinito == 1 ? 2147483647 : parseInt(intervalo.to));
            if (intervalo.infinito) haveInfinito = true;
        }

        intervalos.sort((a, b) => a - b);
        
        // Verificar se começa em ZERO
        if (intervalos[0] != 0) response = false;

        // Retira o menor e maior valor do array
        intervalos.shift();
        intervalos.pop();

        // Valida se todos elementos passuem duas repetiçoes e não sobra nenhum com menos ou mais de duas
        for (let i = 0; i < intervalos.length; i += 2) {
            if (intervalos[i] !== intervalos[i + 1]) response = false;
        }

        return response;
    }

    edit = async () => {

        if (!this.state.name) return toast.warn('Informe o Nome do Mapa Estratégico (*)');
        if (!this.state.responsable) return toast.warn('Selecione um Responsável (*)');
        if (!this.state.tipoProgresso && this.state.tipoProgresso != 0) return toast.warn('Selecione um tipo de Metodologia de Progresso dos Projetos (*)');

        this.setState({ loading: true });

        if (!this.intervalosIsValid()) {
            this.setState({ loading: false });
            return toast.warn('As faixas definidas não cobrem todo intervalo.');
        }

        let resposta = await Sig.request('POST', `pe/mapa/update`, {
            id: this.props.id,
            nome: this.state.name,
            id_responsavel: this.state.responsable.value,
            id_pe_visao: this.state.visao ? this.state.visao.value : null,
            data_inicio: DataHelper.getDefaultDbDateFormat(this.state.data_inicio),
            data_fim: DataHelper.getDefaultDbDateFormat(this.state.data_fim),
            colaboradores: this.state.participants.map((participant) => { return participant.value }) || [],
            norteadores: this.state.norteadores ? JSON.stringify(this.state.norteadores) : [],
            perspectivas: this.state.perspectivas ? JSON.stringify(this.state.perspectivas) : [],
            intervalos: this.state.intervalos ? JSON.stringify(this.state.intervalos) : [],
            tipo_progresso: this.state.tipoProgresso,
            permissao_indicador: this.state.permissao_indicador,
            permissao_projeto: this.state.permissao_projeto,
            width: this.state.width
        });

        if (resposta.mapa.id) toast.success('Editado com sucesso');

        await this.loadData();
    }

    remove = async () => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', `pe/mapa/remove`, { id: this.props.id });
            toast.success('Removido com sucesso');
        } catch (e) {
            toast.error('Erro ao remover');
        }

        if (this.props.deleteCallback) this.props.deleteCallback();
    }

    renderDatePickerInicio() {        
            
        const ExampleCustomInput1 = forwardRef(({value,onClick }, ref) => (
          <Form.Control onClick={onClick} ref={ref} value={value} onChange={() => {}}>
          </Form.Control>
        ));
        let dataMoment1 = this.state.data_inicio;
        let momentConvertidoData1 = moment(dataMoment1).toDate();
  
        return (
            <DatePicker  
                wrapperClassName="w-100"                                                            
                selected={momentConvertidoData1}
                type="date"                                                              
                onChange={(event) => {                   
                    let novoInicio= moment(event);
                    this.setState({ data_inicio: novoInicio});
                    this.setState({ data_fim: novoInicio});
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
            />
        )
    };
    
    renderDatePickerFim() {        
        
        const ExampleCustomInput2 = forwardRef(({value,onClick }, ref) => (
        <Form.Control onClick={onClick} ref={ref} value={value} onChange={() => {}} className="w-100">
        </Form.Control>
        ));
        let dataMoment2 = this.state.data_inicio;
        let momentConvertidoDataInicio = moment(dataMoment2).toDate();    
        
        let dataMoment3 = this.state.data_fim;
        let momentConvertidoDataFim = moment(dataMoment3).toDate();    

        return (
            <DatePicker
            wrapperClassName="w-100"                                                              
            selected={momentConvertidoDataFim}
            type="date"                                                               
            onChange={(event) => {

                let novoFimMoment= moment(event);
                
                if(novoFimMoment >= momentConvertidoDataInicio){
                    this.setState({ data_fim: novoFimMoment})
                }else{
                    toast.info('Data de fim deve ser maior ou igual à data de início');
                }      
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            customInput={<ExampleCustomInput2 />}
        />
        )
    };

    renderForm() {

        return (
            <div className="mt-5 mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                <Row className="mb-3">
                    <Form.Group as={Col}>
                            <Form.Label>Nome do mapa estratégico*</Form.Label>
                            <Form.Control defaultValue={this.state.name} type="text" placeholder="Mapa X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select value={this.state.responsable} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select value={this.state.participants} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} xs={3} md={3}>
                            <Form.Label>Inicio do período*</Form.Label>
                            {this.renderDatePickerInicio()}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} md={3}>
                            <Form.Label>Fim do período*</Form.Label>
                            {this.renderDatePickerFim()}
                        </Form.Group>
                        <Form.Group as={Col} xs={6} md={6}>
                            <Form.Label>Metodologia de Progresso dos Projetos*</Form.Label>
                            <br></br>
                            <Select    
                                loading={this.state.loading}                    
                                options={this.state.tiposProgresso}
                                value={this.state.tiposProgresso.find((tipo) => tipo.value == this.state.tipoProgresso)}
                                placeholder={'Selecione uma opção'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                onChange={(value) => { this.setState({ tipoProgresso: value.value }) }}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Vincular com visão de futuro*</Form.Label>
                            <Select value={this.state.visao} options={DataHelper.formatSelectData(this.state.visoes, 'id', 'nome')} placeholder={'Selecione a visão'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ id_pe_visao: value ? value : null }) }} />
                        </Form.Group>
                    </Row>

                    <AddNorteadores value={this.state.norteadores} callback={(norteadores) => { this.setState({ norteadores }) }}/>
                    <AddPerspectivas value={this.state.perspectivas} callback={(perspectivas) => { this.setState({ perspectivas }) }}/>
                    <IntervalSelector value={this.state.intervalos} callback={(intervalos) => { this.setState({ intervalos }) }}/>

                    <Row className="mb-5">
                        <Form.Group as={Col}>
                            <Form.Label>Permissões de Visualização</Form.Label>
                            <Form.Check type="checkbox" id="checkbox-indicadores">
                                <Form.Check.Input
                                    type="checkbox"
                                    style={{cursor: 'pointer'}}
                                    checked={this.state.permissao_indicador}
                                    onChange={() => this.setState({permissao_indicador: this.state.permissao_indicador === 1 ? 0 : 1})}
                                />
                                <Form.Check.Label style={{cursor: 'pointer', display: 'flex'}}>
                                    Exibir Performance de Indicadores para todos os colaboradores
                                    <CustomTooltip style={{width: 22}} tooltip={'Ao marcar essa opção, a performance dos indicadores estratégicos será exibida para todos os colaboradores, assim como as metas e os resultados. Ao não marcar, essas informações serão exibidas somente para participantes do Mapa Estratégico.'}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}}/>
                                    </CustomTooltip>
                                </Form.Check.Label>
                            </Form.Check>
                            
                            <Form.Check type="checkbox" id="checkbox-projetos">
                                <Form.Check.Input
                                    type="checkbox"
                                    style={{cursor: 'pointer'}}
                                    checked={this.state.permissao_projeto}
                                    onChange={() => this.setState({permissao_projeto: this.state.permissao_projeto === 1 ? 0 : 1})}
                                />
                                <Form.Check.Label style={{cursor: 'pointer', display: 'flex'}}>
                                    Exibir Progresso de Projetos para todos os colaboradores
                                    <CustomTooltip style={{width: 22}} tooltip={'Ao marcar essa opção, o progresso dos projetos será exibido para todos os colaboradores. Ao não marcar, essas informações serão exibidas somente para participantes do Mapa Estratégico.'}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}}/>
                                    </CustomTooltip>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTrash} />} title={'Excluir'} loading={this.state.loading} onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Excluir este Mapa?'}
                                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.remove(); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }} />
                            <DefaultButton className="ms-1" color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSave} />} title={'Salvar Alterações'} loading={this.state.loading} onClick={this.edit} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.settingsCallback() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    render() {
        return this.state.loading ? <LoadingPage /> : (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderForm()}
            </div>
        );
    }
}
import React from "react";
import Sig from "../../../../../api/Sig";
import DataHelper from "../../../../../helper/DataHelper";
import Colors from "../../../../../constants/Colors";

import EllipsisText from "../../../../tools/EllipsisText";
import CustomTooltip from "../../../../tools/CustomTooltip";
import TooManyAvatars from "../../../../tools/TooManyAvatars";
import GaugeCircle from "../../../../tools/GaugeCircle/GaugeCircle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation, faChevronDown, faInfoCircle, faLock } from "@fortawesome/free-solid-svg-icons";

import './ProjetoObjetivoCardChart.css';
import SessionHelper from "../../../../../helper/SessionHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";

export default class ProjetoObjetivoCardChart extends React.Component {
    state = {
        loading: true,
        objetivo: null,
        temIniciativa: false,
    }

    async componentDidMount() {
        await this.loadItem();
    }

    async loadItem(callbeck = true) {
        this.setState({ loading: true });

        let objetivo = callbeck ? this.props.objetivo : this.state.objetivo;

        objetivo.responsaveis = await Sig.request('GET', `pe/mapa/getResponsaveisObjetivos`, { id: this.props.objetivo.id });
        objetivo.acompanhamento = await Sig.request('POST', `pe/diagrama/getAcompanhamentoObjetivoElemento`, { id: this.props.objetivo.id, model: 'PeMapaElemento' });
        objetivo.progress = await Sig.request('GET', `pe/mapa/getObjetivoProgress`, { id: this.props.objetivo.id });
        let { status_text } = await Sig.request('GET', `pe/mapa/getObjetivoStatus`, { id: this.props.objetivo.id });
        objetivo.status_text = status_text;

        this.setState({ objetivo, loading: false });
    }

    renderTitle() {
        return (
            <div className="title" style={{ minHeight: '2.5rem' }}>
                <EllipsisText text={this.state.loading ? 'Carregando...' : DataHelper.removeHtmlAndReplaceListItems(this.state.objetivo.valor)} />
            </div>
        )
    }

    renderGauge() {

        let progress;
        let color;

        if (this.props.tipeView == 'indicadores') {
            progress = this.state.loading ? 0 : Number(this.state.objetivo.acompanhamento.media.performanceRealizado.slice(0, -1));
            color = this.state.loading ? Colors.homePage.lightGrey : this.state.objetivo.acompanhamento.media.cor;
        } else {
            progress = this.state.loading ? 0 : Number(this.state.objetivo.progress.progress);
            color = this.state.loading ? Colors.homePage.lightGrey : Colors.planoAcao.status.backgroundColor[this.state.objetivo?.status_text];
        }

        let hasProgress = progress >= 0;

        const gauge = () => {

            return (
                <GaugeCircle
                    min={0}
                    max={100}
                    progress={progress}
                    color={color}
                />
            );
        }

        const emptyGauge = () => {
            return (
                <>
                    <div style={{ minHeight: '100%' }}>
                        <CustomTooltip tooltip="Sem indicadores vinculados">
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: Colors.danger, fontSize: '3rem' }} />
                        </CustomTooltip>
                    </div>
                    <div style={{ minHeight: '100%' }}>&nbsp;</div>
                </>
            );
        }

        const nonPermission = () => {
            return (
                <div className={"nonPermission"}>
                    <div className={"circle"}>
                        <CustomTooltip tooltip={"Sem Permissão de Visualização"}>
                            <FontAwesomeIcon icon={faLock} color="white" />
                        </CustomTooltip>
                    </div>
                </div>
            )
        }

        if (!this.props.permissao_indicador && this.props.tipeView == 'indicadores') return nonPermission();
        if (!this.props.permissao_projeto && this.props.tipeView != 'indicadores') return nonPermission();
        if (this.state.loading || hasProgress) return gauge();
        return emptyGauge();
    }

    renderFooter(isSelected) {

        const tooltipText = () => {

            if (this.state.loading) return 'Carregando...';

            const indicadoresText = 'Média da performance dos indicadores vinculados a esse Objetivo';
            const projetosText = 'Progresso dos Projetos vinculados a esse Objetivo';

            return this.props.tipeView === 'indicadores' ? indicadoresText : projetosText;
        }

        const avatars = () => {
            return !this.state.loading && <TooManyAvatars participantsTotal={this.state.objetivo.responsaveis.length} participantsVisiveis={2} arrayParticipants={this.state.objetivo.responsaveis} />
        }

        const infos = () => {
            return (
                <div style={{ ...EssentialStyle.rowFlexEnd, gap: 10 }}>
                    <CustomTooltip tooltip={tooltipText()} placement={'top'}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </CustomTooltip>

                    <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ transform: isSelected ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
                    />
                </div>
            )
        }

        return (
            <div className="footer">
                {avatars()}
                {infos()}
            </div>
        )
    }

    render() {

        let isSelected = this.props.isSelected ? this.props.isSelected : false;

        return (

            <div
                className={`objetivoCardChart ${isSelected ? 'selected' : ''}`}
                style={{
                    cursor: this.props.onClick ? 'pointer' : 'default',
                    ...(this.props.style || {}),
                    ...(isSelected ? this.props.selectedStyle || {} : this.props.notSelectedStyle || {}),
                }}
                onClick={this.props.onClick ? this.props.onClick : null}
            >
                {this.renderTitle()}
                {this.renderGauge()}
                {this.renderFooter(isSelected)}
            </div>
        );
    }
}
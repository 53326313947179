import { faSearch, faExclamation, faSlidersH, faTimes, faExchangeAlt, faStar, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultButton from "../../../tools/DefaultButton";
import React from "react";
import { Form, Row, Col, InputGroup, Collapse } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import LoadingPage from "../../../../pages/LoadingPage";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import ObjetivoEstrategicoMeta from "./ObjetivoEstrategicoMeta";
import Sig from "../../../../api/Sig";
import DefaultIndicador from "../../../tools/DefaultIndicador";
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";
import LayoutHelper from "../../../../helper/LayoutHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import MetaPlurianual from "./MetaPlurianual";
import { toast } from "react-toastify";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import EllipsisText from "../../../tools/EllipsisText";
import ModalProjetosObjetivo from "./ModalProjetosObjetivo";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";


const searchableFields = ['nome', 'responsavel', 'participants', 'data_atualizacao', 'data_cadastro'];

export default class MapaTableView extends React.Component {
    state = {
        step: 0,
        loading: true,
        loadingTable: false,
        loadingCell: false,
        mapa: [],
        participants: [],
        name: '',
        participants: [],
        view: 'normal',
        norteadores: [],
        perspectivas: [],
        intervalos: [],
        lineLenght: 4,
        lineHeight: '5em',
        lineCharsLimit: 75,
        scrollIsOn: null,
        viewMetas: false,

        // states do filtro básico
        text: '',

        // states do filtro avançado
        advancedFilter: false,
        textPerspectiva: '',
        textObjetivo: '',
        textReponsavel: '',
        textIndicadores: '',
        textProjeto: '',
        textPerformance: 0,
        typePerformance: 'acima',
        textStatus: '',
        colorsStars: [],

        // Opçoes dos seletores do filtro avançado
        listResponsaveis: [],
        listIndicadores: [],
        listPaineis: [],
        listStatus: [],

        hoverObjetivo: null,
        showModalProjetos: null,
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.scrollIsOn === null) {
            this.verifyScroll();
        } else if (this.state.scrollIsOn === false || this.state.scrollIsOn === true) {
            if (this.state.scrollIsOn !== prevState.scrollIsOn) {
                this.verifyScroll();
            }
        }
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScroll);
        await this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScroll);
    }

    async loadData(loading = true) {

        if (loading) {
            this.setState({ loading: true });
        }

        let mapaRequest = (await Sig.request('GET', `pe/mapa/getMapa`, { id: this.props.id })) || null;
        let { elementos, participantes } = await Sig.request('POST', `pe/diagrama/getElementos`, { id: this.props.id, model: "PeMapa" });

        if (elementos == 0) this.setState({ showDiagram: true });

        let perspectivas = [];

        if (mapaRequest?.mapa?.id) {
            let mapa = mapaRequest.mapa;
            let isFavorite = (mapa.favorito == 1);
            let participants = mapaRequest.participantes;
            let norteadores = mapaRequest.norteadores;
            let perspectivasRaw = mapaRequest.perspectivas;
            let intervalos = mapaRequest.intervalos;
            let metas = mapaRequest.metas;

            for (let index = 0; index < perspectivasRaw.length; index++) {
                perspectivas[index] = perspectivasRaw[index].perspectiva;
                perspectivas[index].objetivos = perspectivasRaw[index].objetivos.map((objetivoStruct) => {
                    let indicadores = objetivoStruct.indicadores;
                    return { ...objetivoStruct.objetivo, indicadores };
                });
            }

            let colorsStars = [];
            let listResponsaveis = [];
            let listIndicadores = [];
            let listPaineis = [];

            for (let perspectiva of perspectivas) {
                for (let objetivo of perspectiva.objetivos) {
                    objetivo.responsaveis = (participantes[objetivo.id] && participantes[objetivo.id].length) ? participantes[objetivo.id] : [];

                    if (objetivo.responsaveis && objetivo.responsaveis.length > 0) listResponsaveis = listResponsaveis.concat(objetivo.responsaveis);
                    if (objetivo.indicadores && objetivo.indicadores.length > 0) {
                        listIndicadores = listIndicadores.concat(objetivo.indicadores.map((i) => (i.id_indicador)));
                        listPaineis = listPaineis.concat(objetivo.indicadores.map((i) => (i.id_gmr_painel)));
                    } 

                    for (let indicador of objetivo.indicadores) {
                        if (!objetivo.performanceCompare) objetivo.performanceCompare = {};
                        objetivo.performanceCompare[this.getIndicadorKey(indicador.id_indicador, indicador.id_gmr_painel)] = await this.comparePerformance(objetivo.id, indicador.id_indicador, indicador.id_gmr_painel);
                    }

                    colorsStars[objetivo.id] = objetivo.prioritario;
                }
            }

            listPaineis = [...new Set(listPaineis)];
            listIndicadores = [...new Set(listIndicadores)];
            
            listResponsaveis = await DataHelper.formatSelectIdUserData(listResponsaveis.map((responsavel) => { return responsavel.id_participante }), 'id', 'nome');
            listIndicadores = await DataHelper.formatSelectIdIndicadorData(listIndicadores, 'id', 'nome');
            listPaineis = await DataHelper.formatSelectIdPainelData(listPaineis, 'id', 'nome');

            this.setState({
                listResponsaveis,
                listIndicadores,
                listPaineis,
                colorsStars,
                participants,
                norteadores,
                perspectivas,
                mapa,
                name: mapa.nome,
                isFavorite,
                intervalos,
                metas
            },
                () => {

                    this.loadProjetos();
                }
            );
        }
        return perspectivas;
    }

    getIndicadorKey(id_indicador, id_gmr_painel) {
        return `${id_indicador}-${id_gmr_painel}`;
    }

    async loadProjetos() {

        this.setState({ loading: true });

        let perspectivas = [...this.state.perspectivas];

        for (let perspectiva of perspectivas) {
            for (let objetivo of perspectiva.objetivos) {
                let { projetos } = await Sig.request('GET', `pe/projeto/getProjetosObjetivo`, { id_objetivo: objetivo.id });
                objetivo.projetos = projetos;
            }
        }

        this.setState({ perspectivas }, () => { this.setState({ loading: false }) });
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }

    async filterObjetivos(text) {

        this.setState({ text });

        if (text && text.length && text.length > 2) {

            text = text.toLowerCase();

            let perspectivas = await this.loadData(false);
            let result = [];

            perspectivas.forEach((perspectiva, key) => {

                if (perspectiva.objetivos) {

                    let objetivos = [];

                    perspectiva.objetivos.forEach((objetivo, objetivoKey) => {

                        if (objetivo.valor.toLowerCase().indexOf(text) >= 0) {

                            objetivos.push(objetivo);
                        }
                    });

                    perspectiva.objetivos = objetivos;
                    result.push(perspectiva);
                }
            });

            result = result.filter(perspectiva => {
                return perspectiva.objetivos ? perspectiva.objetivos.length > 0 : false;
            });

            this.setState({ perspectivas: result }, () => { this.verifyScroll(); });

        } else {

            this.loadData(false);
        }
    }

    addAdvancedFilter = () => {
        this.setState({
            advancedFilter: !this.state.advancedFilter
        },
            () => {
                if (this.state.intervalos.length > 0) {
                    let gradient = 'linear-gradient(to right, ';

                    this.state.intervalos.forEach((intervalo, key) => {
                        let vals = ['minimo', 'maximo'];

                        if (intervalo.minimo > 100) intervalo.minimo = 100;
                        if (intervalo.maximo > 100) intervalo.maximo = 100;

                        vals.forEach((val, keyVal) => {
                            gradient += `${intervalo.cor} ${intervalo[val]}%,`;
                        });
                    });

                    gradient = gradient.substring(0, gradient.length - 1);
                    gradient += ')';


                    LayoutHelper.setSliderColors(gradient);

                }

                setTimeout(() => {
                    this.verifyScroll();
                }, 500);

            });
    }

    inverterPerformance = () => {
        this.setState({ typePerformance: this.state.typePerformance === 'acima' ? 'abaixo' : 'acima' });
    }

    async advancedFilter() {

        this.setState({ loadingTable: true });
        let perspectivas = await this.loadData(false);

        // Filtro por perspectiva
        if (this.state.textPerspectiva && this.state.textPerspectiva.length > 0) {
            perspectivas = perspectivas.filter(perspectiva => {
                return perspectiva.nome.toLowerCase().indexOf(this.state.textPerspectiva.toLowerCase()) >= 0;
            });
        }

        // Filtro por objetivo
        if (this.state.textObjetivo && this.state.textObjetivo.length > 0) {
            perspectivas.forEach(perspectiva => {
                perspectiva.objetivos = perspectiva.objetivos.filter(objetivo => {
                    return objetivo.valor.toLowerCase().indexOf(this.state.textObjetivo.toLowerCase()) >= 0;
                });
            });

            perspectivas = perspectivas.filter(perspectiva => {
                return perspectiva.objetivos ? perspectiva.objetivos.length > 0 : false;
            });
        }

        // Filtro por responsável
        if (this.state.textReponsavel && this.state.textReponsavel.length > 0) {
            perspectivas.forEach(perspectiva => {
                perspectiva.objetivos = perspectiva.objetivos.filter(objetivo => {
                    return objetivo.responsaveis && objetivo.responsaveis.length > 0 && this.state.textReponsavel.some(responsavel =>
                        objetivo.responsaveis.some(obj => obj.id_participante == responsavel.value)
                    );
                });
            });

            perspectivas = perspectivas.filter(perspectiva => {
                return perspectiva.objetivos ? perspectiva.objetivos.length > 0 : false;
            });
        }

        // Filtro por indicador
        if (this.state.textIndicadores && this.state.textIndicadores.length > 0) {
            perspectivas.forEach(perspectiva => {
                perspectiva.objetivos = perspectiva.objetivos.filter(objetivo => {
                    return objetivo.indicadores && objetivo.indicadores.some(indicador =>
                        this.state.textIndicadores.some(i => i.value == indicador.id_indicador)
                    );
                });
            });

            perspectivas = perspectivas.filter(perspectiva => {
                return perspectiva.objetivos ? perspectiva.objetivos.length > 0 : false;
            });
        }

        // Filtro por performance
        if (this.state.textPerformance && this.state.textPerformance > 0) {
            perspectivas.forEach(perspectiva => {
                perspectiva.objetivos = perspectiva.objetivos.filter(objetivo => {
                    if (!objetivo.performanceCompare) {
                        return false;
                    }

                    return objetivo.indicadores.some(indicador => {
                        let id_indicador = indicador.id_indicador;
                        let id_gmr_painel = indicador.id_gmr_painel;

                        if (!objetivo.performanceCompare[this.getIndicadorKey(id_indicador, id_gmr_painel)]) {
                            return false;
                        }

                        if (this.state.typePerformance === 'acima') {
                            return parseFloat(objetivo.performanceCompare[this.getIndicadorKey(id_indicador, id_gmr_painel)]) > parseFloat(this.state.textPerformance);
                        } else {
                            return parseFloat(objetivo.performanceCompare[this.getIndicadorKey(id_indicador, id_gmr_painel)]) < parseFloat(this.state.textPerformance);
                        }
                    });
                });
            });

            perspectivas = perspectivas.filter(perspectiva => {
                return perspectiva.objetivos ? perspectiva.objetivos.length > 0 : false;
            });
        }

        // Filtro por projeto
        // Desenvolver ...

        // Filtro por status
        // Desenvolver ...

        this.setState({ perspectivas, loadingTable: false }, () => { this.verifyScroll(); });
    }

    async comparePerformance(id, id_indicador, id_gmr_painel) {
        let req = await Sig.request('POST', `pe/diagrama/getAcompanhamentoObjetivoElemento`, {
            id,
            id_indicador,
            id_gmr_painel,
            model: "PeMapaElemento",
        });

        return req?.media ? (req?.media.performanceRealizado ? (req?.media.performanceRealizado != '--%' ? (req?.media.performanceRealizado.replace('%', '')) : (null)) : (null)) : (null);
    }

    cleanFilter = () => {

        this.setState({
            textPerspectiva: '',
            textObjetivo: '',
            textReponsavel: '',
            textIndicadores: '',
            textProjeto: '',
            textPerformance: 0,
            textStatus: ''
        });

        this.advancedFilter();
    }

    async savePrioridade(id, prioridade) {

        let req = await Sig.request('POST', `pe/mapa/alterarPrioridade`, { id: id, prioridade });
        req.status != 200 ? toast.error('Erro ao alterar prioridade') : this.setState({ colorsStars: { ...this.state.colorsStars, [id]: prioridade } });

    }

    openModalProjetos(idObjetivo) {
        this.setState({ showModalProjetos: idObjetivo == this.state.showModalProjetos ? null : idObjetivo });
    }

    renderParticipants(participants) {
        if (!Array.isArray(participants)) {
            participants = [participants];
        }

        if (participants && participants.length > 0) {

            participants = participants.map((participant) => { return participant.id_participante });
            return (
                <div style={{ display: 'flex', height: '5em', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5 }}>
                    <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={3} arrayParticipants={participants} />
                </div>
            )
        } else {
            return <div></div>
        }
    }

    renderObjetivoCell(objetivo) {

        return (
            <div style={{ ...EssentialStyle.columnStart, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: '1.5em' }}>
                    <span>{this.renderStarVisualizacao(objetivo.id)}</span>
                </div>

                <div
                    style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%',
                        height: 'calc(100% - 2.0em)',
                        maxHeight: 'calc(100% - 2.0em)',
                        padding: '0px 3px',
                    }}>

                    <EllipsisText text={DataHelper.removeHtmlTags(objetivo.valor)} />
                </div>

                <div style={{ ...EssentialStyle.rowFlexStart, padding: 3, height: '0.5em', width: '100%' }}></div>
            </div>
        )
    }

    renderProjetosCell(objetivo) {
        let projetosLength = objetivo?.projetos?.length || 0;

        return (
            <div style={{ ...EssentialStyle.columnSpaceBetween, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, fontWeight: 'bold' }}>
                <div />
                <span
                    className={projetosLength > 0 ? "icon-container" : ""}
                    style={{ cursor: projetosLength > 0 && this.props.permissao_projeto ? 'pointer' : 'default' }}
                    onClick={() => { projetosLength > 0 && this.props.permissao_projeto && this.openModalProjetos(objetivo.id) }}
                >
                    {`${projetosLength} Projeto${projetosLength > 1 ? 's' : ''}`}
                </span>
                {
                    projetosLength > 0 ?
                        <ProgressBarCustom tipo={'objetivo'} id={objetivo.id} style={{ width: '100%', padding: '5px 5px' }} />
                        : <div />
                }
            </div>
        )
    }

    renderDadosfaltantes(rowSpanLength = 1, colSpanLength = 1) {

        let heightCalc = colSpanLength < 8 ? this.state.lineHeight : 36;
        return (

            <td rowSpan={rowSpanLength} colSpan={colSpanLength} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <div style={{ ...EssentialStyle.rowFlexCenter, height: heightCalc, width: '100%' }}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#ffc107', display: 'inline-block', marginRight: 5, padding: 3 }}>
                        <FontAwesomeIcon icon={faExclamation} color="white" />
                    </div>
                    Sem dados
                </div>
            </td>
        )
    }

    renderStarVisualizacao(id) {

        let colorStar = this.state.colorsStars[id] == 1 ? Colors.projeto.starOn : Colors.projeto.starOff;
        return (
            <FontAwesomeIcon
                icon={faStar}
                style={{
                    color: colorStar,
                    fontSize: '1em',
                    transition: 'color 0.15s ease-in-out',
                    cursor: this.props.podeFavoritarObjetivo ? 'pointer' : 'default',
                    padding: 3
                }}
                onClick={() => {
                    if (this.props.podeFavoritarObjetivo) {
                        this.savePrioridade(id, this.state.colorsStars[id] == 1 ? 0 : 1);
                    }
                }}
            />
        );
    }

    handleSort(index) {

        let sortType = '';

        if (this.state.sort !== null && this.state.sort === index) {

            if (this.state.sortType === 'asc') {

                sortType = 'desc'

            } else {

                sortType = 'asc'
            }

        } else {

            sortType = 'asc';

        }

        this.setState({ sort: index, sortType });
        this.sortPerspectivas(index, sortType);
    }

    verifyScroll = () => {

        let element = document.querySelector('.divScroll');

        if (element) {
            // Verifique se o scroll vertical está ativo
            this.setState({ scrollIsOn: element.scrollHeight > element.clientHeight });
        }
    }

    renderHeader() {
        return (
            <tr>
                <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Perspectiva</th>
                <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Objetivos</th>
                <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Responsável</th>
                <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Projetos</th>
                <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Indicadores</th>
                {!this.state.viewMetas ?
                    <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Resultado</th> : null
                }
                {!this.state.viewMetas ?
                    <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Performance</th> : null
                }
                {this.state.viewMetas ?
                    this.state.metas?.anos.map((ano, key) => {
                        let date = new Date(ano);
                        let year = date.getFullYear();
                        return (
                            <th colSpan={2} style={{ position: 'sticky', top: 0, zIndex: 1 }}>Meta {year}</th>
                        );
                    })
                    : null}
            </tr>
        )
    }

    renderTable() {
        return this.state.perspectivas.map((perspectiva, key) => {
            if (perspectiva.objetivos.length > 0) {

                let perspectivaRowSpan = perspectiva.objetivos.reduce((total, objetivoSub) => total + (objetivoSub.indicadores?.length || 1), 0);

                return perspectiva.objetivos.map((objetivo, objetivoKey) => {

                    if (!objetivo.indicadores?.length) objetivo.indicadores = [null];

                    let objetitvoRowSpan = objetivo.indicadores?.length || 1;

                    return objetivo.indicadores.map((indicador, indicadorKey) => {
                        return (
                            <tr>
                                {
                                    indicadorKey == 0 && objetivoKey == 0 ?
                                        perspectiva.nome ?
                                            <td colSpan={2} rowSpan={perspectivaRowSpan}>{DataHelper.removeHtmlTags(perspectiva.nome)}</td>
                                            : this.renderDadosfaltantes(perspectivaRowSpan)
                                        : null
                                }
                                {
                                    indicadorKey == 0 ?
                                        objetivo.valor ?
                                            <td colSpan={2} rowSpan={objetitvoRowSpan} style={{ position: 'relative' }}>
                                                {this.renderObjetivoCell(objetivo)}
                                            </td>
                                            : this.renderDadosfaltantes(objetitvoRowSpan, 2)
                                        : null
                                }
                                {
                                    indicadorKey == 0 ?
                                        objetivo.responsaveis && objetivo.responsaveis.length > 0 ?
                                            <td colSpan={2} rowSpan={objetitvoRowSpan}>{this.renderParticipants(objetivo.responsaveis)}</td>
                                            : this.renderDadosfaltantes(objetitvoRowSpan, 2)
                                        : null
                                }
                                {
                                    indicadorKey == 0 ?
                                        objetivo.valor ?
                                            <td colSpan={2} rowSpan={objetitvoRowSpan} style={{ position: 'relative' }}>
                                                {this.renderProjetosCell(objetivo)}
                                            </td>
                                            : this.renderDadosfaltantes(objetitvoRowSpan, 2)
                                        : null
                                }
                                {

                                    indicador ?
                                        <td colSpan={2} rowSpan={1}>{<DefaultIndicador id_indicador={indicador.id_indicador} id_gmr_painel={indicador.id_gmr_painel} />}</td>
                                        : this.renderDadosfaltantes(1, 2)

                                }
                                {
                                    !this.state.viewMetas ?
                                        indicador?.id_gmr_painel ?
                                            <td colSpan={2} rowSpan={1}>
                                                <ObjetivoEstrategicoMeta
                                                    justifyContent={'center'}
                                                    id={objetivo.id}
                                                    id_indicador={indicador?.id_indicador}
                                                    id_gmr_painel={indicador?.id_gmr_painel}
                                                    grafico={"resultado"}
                                                    model={"PeMapaElemento"}
                                                    height={60}
                                                />
                                            </td>
                                            : this.renderDadosfaltantes(1, 2)
                                        : null
                                }
                                {
                                    !this.state.viewMetas ?
                                        indicador?.id_gmr_painel ?
                                            <td colSpan={2} rowSpan={1}>
                                                <ObjetivoEstrategicoMeta
                                                    justifyContent={'center'}
                                                    id={objetivo.id}
                                                    id_indicador={indicador?.id_indicador}
                                                    id_gmr_painel={indicador?.id_gmr_painel}
                                                    grafico={"performance"}
                                                    model={"PeMapaElemento"}
                                                    minHeight={this.state.lineHeight}
                                                />
                                            </td>
                                            : this.renderDadosfaltantes(1, 2)
                                        : null
                                }
                                {
                                    indicador?.id_gmr_painel ?
                                        this.state.viewMetas && this.state.metas?.anos?.length > 0 ?
                                            this.state.metas.anos.map((ano, key) => {
                                                return (
                                                    <td colSpan={2} rowSpan={1}>
                                                        <MetaPlurianual
                                                            ano={ano}
                                                            id_objetivo={objetivo.id}
                                                            id_indicador={indicador?.id_indicador}
                                                            id_gmr_painel={indicador?.id_gmr_painel}
                                                        />
                                                    </td>
                                                );
                                            })
                                            : null
                                        : (this.state.viewMetas ? this.renderDadosfaltantes(1, this.state.metas.anos.length * 2) : null)
                                }
                            </tr>
                        );
                    });
                });

            } else {
                let anos = Object.values(this.state.metas.anos);
                return (
                    <tr>
                        <td rowSpan={1} colSpan={2}>{DataHelper.removeHtmlTags(perspectiva.nome)}</td>
                        {this.renderDadosfaltantes(1, (this.state.viewMetas ? (6 + (anos.length * 2)) : 10))}
                    </tr>
                )
            }
        })
    }

    renderSearch() {
        const iconStyle = this.state.isHovered ? { transform: 'scale(1.1)' } : {};
        return (
            <InputGroup>
                <Form.Control
                    placeholder={this.state.advancedFilter ? "Utilize a Busca Avançada" : "Buscar por objetivos..."}
                    type="text"
                    onChange={(event) => { 
                        this.setState({ text: event.target.value });

                        clearTimeout(this.searchTimeout);
                        this.searchTimeout = setTimeout(() => {
                            this.filterObjetivos(event.target.value);
                        }, 400);
                    }}
                    disabled={this.state.advancedFilter}
                    value={this.state.advancedFilter ? "" : this.state.text}
                />

                <InputGroup.Text onClick={() => this.addAdvancedFilter()}>
                    <CustomTooltip placement={'left'} tooltip={'Filtro Avançado'}>
                        <FontAwesomeIcon
                            icon={faSlidersH}
                            style={iconStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave} />
                    </CustomTooltip>
                </InputGroup.Text>
            </InputGroup>
        )
    }

    renderAdvancedSearch() {
        return (
            <Collapse in={this.state.advancedFilter}>
                <div className="box-advancedSearch" style={{ backgroundColor: '#fff', borderRadius: 5, padding: 10 }}>
                    <Form className="w-100">
                        <Row className="m1-3">
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">Perspectiva</Form.Label>
                                <Form.Control className="form-control-xsm" type="text" placeholder="Perspectiva..." value={this.state.textPerspectiva || ''} onChange={(event) => { this.setState({ textPerspectiva: event.target.value }) }} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">Objetivo</Form.Label>
                                <Form.Control className="form-control-xsm" type="text" placeholder="Objetivo..." value={this.state.textObjetivo || ''} onChange={(event) => { this.setState({ textObjetivo: event.target.value }) }} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">Responsáveis</Form.Label>
                                <Select
                                    className="form-control-xsm"
                                    options={this.state.listResponsaveis}
                                    placeholder={'Selecione um responsável'}
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    value={this.state.textReponsavel}
                                    onChange={(selectedOption) => { this.setState({ textReponsavel: selectedOption }) }}
                                    menuPortalTarget={document.body} />
                            </Form.Group>
                        </Row>

                        <Row className="m1-3" style={{ marginTop: 10 }}>
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">Indicadores</Form.Label>
                                <Select
                                    className="form-control-xsm"
                                    options={this.state.listIndicadores}
                                    placeholder={'Selecione um indicador'}
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    value={this.state.textIndicadores}
                                    onChange={(selectedOption) => { this.setState({ textIndicadores: selectedOption }) }}
                                    menuPortalTarget={document.body} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">Projeto</Form.Label>
                                <Form.Control className="form-control-xsm" disabled={true} type="text" placeholder="Em desenvolvimento..." value={this.state.textProjeto || ''} onChange={(event) => { this.setState({ textProjeto: event.target.value }) }} />
                            </Form.Group>

                            <Form.Group as={Col} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Label className="form-label-xsm space-between">
                                    <span>Performance {this.state.typePerformance} de {this.state.textPerformance}%</span>
                                    <CustomTooltip placement={'top'} tooltip={<span>Alternar entre <strong>Acima</strong> e <strong>Abaixo</strong> de ...</span>}>
                                        <FontAwesomeIcon
                                            icon={faExchangeAlt}
                                            style={{ cursor: 'pointer', color: '#4d4d4d', paddingRight: 5 }}
                                            onClick={() => { this.inverterPerformance(); }} />
                                    </CustomTooltip>
                                </Form.Label>
                                <Form.Control style={{ height: 38 }} type="range" min="0" max="100" value={this.state.textPerformance} onChange={(event) => { this.setState({ textPerformance: event.target.value }) }} />
                            </Form.Group>
                        </Row>

                        <Row className="m1-2" style={{ marginTop: 10 }}>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="form-label-xsm">Status</Form.Label>
                                    <Select
                                        className="form-control-xsm"
                                        options={this.state.listIndicadores}
                                        placeholder={'Em desenvolvimento...'}
                                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                        isClearable
                                        isSearchable
                                        isMulti
                                        isDisabled={true}
                                        value={this.state.textStatus}
                                        onChange={(selectedOption) => { this.setState({ textStatus: selectedOption }) }}
                                        menuPortalTarget={document.body} />
                                </Form.Group>
                            </Col>

                            <Col className="d-flex flex-column justify-content-end">
                                <Form.Group className="text-end">
                                    <DefaultButton className="me-1" color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} title={'Limpar'} loading={this.state.loading} onClick={() => { this.cleanFilter() }} />
                                    <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSearch} />} title={'Filtrar'} loading={this.state.loading} onClick={() => { this.advancedFilter() }} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Collapse>

        )
    }

    render() {

        let widthTable = !this.state.viewMetas ? '100%' : `calc(100% + (${(this.state.metas?.anos?.length <= 2 ? 0 : (this.state.metas?.anos?.length - 2))} * 80px))`;

        return this.state.loading ? <LoadingPage  height={'calc(100vh - 60px)'}/> : (
            <div style={{ ...EssentialStyle.columnStart, minWidth: '100vw', height: 'calc(100vh - 60px)', padding: '10px 0px' }}>

                <div style={{ width: '100%', padding: 10, backgroundColor: '#ededed', border: '1px solid #ccc' }}>
                    <div style={{ ...EssentialStyle.rowFlexStart, minWidth: '100vh' }}>
                        {this.renderSearch()}
                        <DefaultButton
                            style={{ width: 120, marginLeft: 10 }}
                            color={Colors.dark}
                            leftIcon={<FontAwesomeIcon icon={(this.state.viewMetas ? faEye : faEyeSlash)} />}
                            title={'Metas'}
                            loading={this.state.loading}
                            onClick={() => { this.setState({ viewMetas: !this.state.viewMetas }, () => { this.verifyScroll() }) }} />
                    </div>
                    {this.renderAdvancedSearch()}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #ccc', maxHeight: 'calc(100% - 60px)', width: '100vw', maxWidth: '100vw', overflowX: 'auto' }}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: widthTable, maxWidth: widthTable, paddingRight: this.state.scrollIsOn ? 15 : 0, backgroundColor: Colors.tableHeader }}>
                        <table>
                            <thead>
                                {this.renderHeader()}
                            </thead>
                        </table>
                    </div>

                    <div className={'divScroll'} style={{ flex: 1, overflow: 'auto', width: widthTable, maxWidth: widthTable }}>
                        <table>
                            <tbody>
                                {this.state.loadingTable ? <tr><td colSpan={16}><DefaultLoader /></td></tr> : this.renderTable()}
                            </tbody>
                        </table>
                    </div>

                </div>

                {this.state.showModalProjetos && <ModalProjetosObjetivo id={this.state.showModalProjetos} closeModal={() => { this.setState({showModalProjetos: null}) } }/>}
            </div>
        );
    }
}
import React, {Suspense} from 'react';
import './PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import { Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../../constants/Colors';
import SessionHelper from '../../../../helper/SessionHelper';
import DefaultButton from '../../../tools/DefaultButton';
import PlanoAcaoRelatorioTabela from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioTabela';
import PlanoAcaoRelatorioLista from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioLista';
import PlanoAcaoRelatorioStatus from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioStatus';
import PlanoAcaoRelatorioDetails from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioDetails';
import PlanoAcaoRelatorioColaboradores from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioColaboradores';
import PlanoAcaoRelatorioParceiros from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioParceiros';
import Tabs from '../../../tools/Tabs';

export default class PlanoAcaoRelatorio extends React.Component {
    
    state = {
        displayContent: true,
        activeTipoVisaoTab: 'lista',
        tableSort: {
            sort: false,
            sortAsc: true,
            sortField: "",
        },
        filter: {
            view: 'planos',
            values: [],
        }, 
        filteredElements: [],
        view: 'planos',
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        loading: false,
        activeTabTimeline: 'semana',
    }

    componentDidMount() {
        this.setState({ filteredElements: [...this.props.planos] });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.planos !== prevProps.planos) {
            this.setState({ view: 'planos', filteredElements: [...this.props.planos], filter: { view: 'planos', values: [] }, tableSort: { sort: false, sortAsc: true, sortField: "" } }, () => this.filterElements());
        }
    }

    filterElements = async () => {
        if(this.state.filter.view === 'planos') {
            var planos = await this.sortPlanosAcao(await this.filterPlanos());
            this.setState({  filteredElements: [...planos], view: this.state.filter.view, loading: false });
        }
        if(this.state.filter.view === 'etapas') {
            var etapas = await this.filterEtapas(this.props.planos.reduce((acc, plano) => {
                if(plano.etapas && plano.etapas.length > 0) {
                    return acc = [...acc, ...plano.etapas];
                }
                return acc;
            }, []));
            this.setState({ loading: false, view: this.state.filter.view, filteredElements: etapas });
        }
    }
    
    filterEtapas = async (etapas) => {
        return new Promise((resolve, reject) => {
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                etapas = [...etapas].filter(etapa => {
                    return !this.state.filter.values.some((filter) => {
                        var field;

                        if(filter.field === 'responsavel') {
                            field = etapa.colaborador.id;
                        } else if(filter.field === 'parceiro') {
                            let planoEtapa = this.props.planos.find(plano => plano.etapas.some(etapaPlano => etapaPlano.id == etapa.id));

                            field = planoEtapa.id_empresa;
                        } else {
                            field = etapa[filter.field];
                        }

                        if(field === undefined || field === null) return true;

                        if(filter.value == 'Atrasada'){
                            if(etapa.atrasada && etapa.status != 2 && etapa.status != 3){
                                return false;
                            }
                            return true;
                        }

                        if(filter.operation == '>') {
                            return !(field > filter.value)
                        } else if(filter.operation == '<') {
                            return !(field < filter.value)
                        } else 
                            return !(field == filter.value)
                    }   
                )}
            )}
            resolve([...etapas]);
        })
    }

    filterPlanos = async () => {
        return new Promise((resolve, reject) => {
            var planos = [...this.props.planos];
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                planos = planos.filter(plano => {
                    return !this.state.filter.values.some((filter) => {
                        var field;

                        switch(filter.field) {
                            case 'vinculo':
                                if(!plano.vinculos || plano.vinculos.length == 0) return true;
                                return !plano.vinculos.some(vinculo => vinculo.tipo == filter.value);
                            case 'superior':
                                if(plano.superior && plano.aprovacao) 
                                    return false;
                                else 
                                    return true;
                            case 'aprovacao':
                                if(!plano.aprovacao) 
                                    return true;
                                else 
                                    field = plano.aprovacao.status_text;
                                break;
                            case 'vinculos':
                                if(!plano.vinculos) 
                                    field = 0;
                                else
                                    field =  plano.vinculos.length;
                                break;
                            case 'tendencia':
                                if((plano.status != 'Concluído' && plano.status != 'Cancelado') && !plano.verificacao){
                                    if(!plano.tendencia) 
                                        field = 'Sem Informação';
                                    else
                                        field = plano.tendencia.msg;
                                } else {
                                    return true;
                                }
                                break;
                            case 'verificacao':
                                if(!plano.verificacao) 
                                    return true;
                                else
                                    field = plano.verificacao.eficaz;
                                break;
                            case 'responsavel':
                                if(!plano.responsavel) 
                                    return true;
                                else
                                    field = plano.responsavel.id;
                                break;
                            case 'parceiro':
                                if(!plano.id_empresa) 
                                    return true;
                                else
                                    field = plano.id_empresa;
                                break;
                            default:
                                field = plano[filter.field];
                                break;
                        }

                        if(field === undefined || field === null) return true;

                        if(filter.operation == '>') {
                            return !(field > filter.value)
                        } else if(filter.operation == '<') {
                            return !(field < filter.value)
                        } else 
                            return !(field == filter.value)
                    })
                })
            }
            resolve([...planos]);
        })
    }
    
    sortPlanoAcao = (a, b) => {
        if(this.state.tableSort.sortField === "nome") {
            return a[this.state.tableSort.sortField].localeCompare(b[this.state.tableSort.sortField]) * (this.state.tableSort.sortAsc ? 1 : -1);
        }
        if(this.state.tableSort.sortField === "responsavel") {
            return a[this.state.tableSort.sortField].nome.localeCompare(b[this.state.tableSort.sortField].nome) * (this.state.tableSort.sortAsc ? 1 : -1);
        }
        if(this.state.tableSort.sortField === "status") {
            if(this.state.status.indexOf(a[this.state.tableSort.sortField]) < this.state.status.indexOf(b[this.state.tableSort.sortField])) {
                return this.state.tableSort.sortAsc ? -1 : 1;
            } 
            if(this.state.status.indexOf(a[this.state.tableSort.sortField]) > this.state.status.indexOf(b[this.state.tableSort.sortField])) {
                return this.state.tableSort.sortAsc ? 1 : -1;
            }
            return 0;
        }

        if (a[this.state.tableSort.sortField] < b[this.state.tableSort.sortField]) {
            return this.state.tableSort.sortAsc ? -1 : 1;
        }
        if (a[this.state.tableSort.sortField] > b[this.state.tableSort.sortField]) {
            return this.state.tableSort.sortAsc ? 1 : -1;
        }
        return 0;
    }

    sortPlanosAcao = async (planos) => {
        return new Promise((resolve, reject) => {
            if(this.state.tableSort.sort && this.state.tableSort.sortField !== "" && planos.length > 0) {
                planos = planos.sort(this.sortPlanoAcao);
            }
            resolve(planos);
        });
    }

    renderList = () => {
        if(!this.props.planos || this.props.planos.length === 0) return null;
        return (
            <PlanoAcaoRelatorioLista elements={this.state.filteredElements} isSmallScreen={this.props.isSmallScreen} view={this.state.view} loading={this.state.loading}/>
        )
    }

    filter = (filter) => {
        this.setState({ filter, loading: true }, () => this.filterElements());   
    }

    sortTable = (sort) => {
        this.setState({ tableSort: sort, loading: true }, () => this.filterElements());
    }

    renderEmpty = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, ...EssentialStyle.card, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
            </div>
        );
    }

    renderTable = () => {
        if(!this.props.planos || this.props.planos.length === 0) return this.renderEmpty();

        return (
            <PlanoAcaoRelatorioTabela elements={this.state.filteredElements} isSmallScreen={this.props.isSmallScreen} sortCallback={this.sortTable} view={this.state.view} sort={this.state.tableSort} loading={this.state.loading}/>	
        )
    }

    renderTimeline = () => {
        if(!this.props.planos || this.props.planos.length === 0) return this.renderEmpty();

        const PlanoAcaoRelatorioTimeline = React.lazy(() => import('./PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioTimeline/PlanoAcaoRelatorioTimeline'));
        
        return (
            <Suspense 
                fallback={
                    <div style={{...EssentialStyle.columnCenter, width: '100%', height: '40vh'}}>
                        <DefaultLoader/>
                    </div>}
            >
                <PlanoAcaoRelatorioTimeline elements={this.state.filteredElements} isSmallScreen={this.props.isSmallScreen} view={this.state.view} granularidade={this.state.activeTabTimeline}/>
            </Suspense>
        );
    }

    handleTipoVisaoTabs = (tab) => {
        this.setState({ activeTipoVisaoTab: tab.name });
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    renderContent = () => {
        const tabs = [
            { name: 'lista', title: 'Lista' },
            { name: 'tabela', title: 'Tabela' },
            { name: 'timeline', title: 'Timeline' },
        ];

        const timelineTabs = [
            { name: "ano", title: "Ano" },
            { name: 'mes', title: 'Mês' },
            { name: 'semana', title: 'Semana' },
        ];

        return(
        <>
            <div 
                style={{
                    ...EssentialStyle.rowSpaceBetween, 
                    height: 'auto',
                    minHeight: 40, 
                    backgroundColor: SessionHelper.getBackgroundColor(), 
                    width: "100%", 
                    position: "sticky", 
                    left: 0, 
                    flexWrap: "wrap", 
                    gap: 5,
                    margin: '3px 0px',
                }}
            >
                {this.state.displayContent ? 
                    <Tabs 
                        isSmallScreen={this.props.isSmallScreen}
                        tabs={tabs}
                        activeTab={this.state.activeTipoVisaoTab}
                        onTabChange={this.handleTipoVisaoTabs}
                        style={{placeSelf: "center", marginTop: 0}}
                        tabStyle={{width: this.props.isSmallScreen ? '25vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                    />
                : 
                    <div></div>
                }
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10, marginRight: 10, position: 'sticky', left: "calc(100%)"}}>
                    {this.state.activeTipoVisaoTab == 'timeline' && this.state.displayContent &&
                        <Tabs 
                            isSmallScreen={this.props.isSmallScreen}
                            tabs={timelineTabs}
                            activeTab={this.state.activeTabTimeline}
                            onTabChange={this.handleTimelineTabChange}
                            style={{placeSelf: "right", marginTop: 0}}
                            tabStyle={{width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                        />
                    }
                    <DefaultButton 
                        leftIcon={!this.state.displayContent ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                        color={"transparent"}
                        textColor={Colors.dark}
                        onClick={() => this.setState({ displayContent: !this.state.displayContent })}
                        style={{ width: 30, height: 30, alignItems: "center", justifyContent: "center", padding: 0 }}
                    />
                </div>
            </div>
            <div style={{...EssentialStyle.columnStart, borderRadius: 8, maxHeight: this.state.displayContent ? "unset" : 40 , flex: 1, backgroundColor: SessionHelper.getBackgroundColor(), transition: "all 0.5s ease"}}>
                <Collapse in={this.state.displayContent} style={{transition: "all 0.5s ease", height: "calc(100% - 40px)", maxHeight: "calc(100% - 40px)", width: "100%"}}>
                    <div>
                        {!this.props.loading ? 
                            <div>
                                {this.state.activeTipoVisaoTab == 'lista' ? this.renderList() : this.state.activeTipoVisaoTab == 'tabela' ? this.renderTable() : this.renderTimeline()}
                            </div>
                        :   
                            <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                                <DefaultLoader />
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
        </>
        )
    }

    render() {
        if(!this.props.planos || this.props.planos.length === 0 && !this.props.loading) {
            return (
                <div style={{...EssentialStyle.rowSpaceBetween}}>
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                        <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
                    </div>
                </div>
            );
        }

        return (
            <div className={"relatorio-pa"} style={{...EssentialStyle.columnStart, height: `calc(100vh - ${this.props.headerSize}px - 14px)`, width: "calc(100% + 14px)", gap: 2, margin: '0px -7px'}}>
                {this.props.loading ? 
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "100%" }}>
                        <DefaultLoader />
                    </div> 
                : 
                <>
                    <div style={{
                            maxHeight:"100%", 
                            width: "100%", 
                            overflowX: "auto", 
                            padding: '0px 7px',
                            marginBottom: this.props.isSmallScreen ? '6rem' : 0,
                        }}
                    >
                        <PlanoAcaoRelatorioStatus planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/>
                        <PlanoAcaoRelatorioDetails planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/>
                        {!this.props.parceiro ? <PlanoAcaoRelatorioColaboradores planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/> : <PlanoAcaoRelatorioParceiros planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter}/>}
                        {this.renderContent()}
                    </div>
                </>
                }
            </div>
        );
    }
}
import React from "react";
import './ProgressBarCustom.css';

import { ProgressBar } from "react-bootstrap";
import LayoutHelper from "../../../helper/LayoutHelper";
import Sig from "../../../api/Sig";
import Colors from "../../../constants/Colors";
import EssentialStyle from "../../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

export default class ProgressBarCustom extends React.Component {
    state = {
        loading: true,
        tipo: this.props.tipo,
        id: this.props.id,
        min: 0,
        max: 100,
        progress: 0,
        label: '',
        progressBarHovered: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        permissionView: this.props.permissionView || true,
        completeEfect: this.props.completeEfect,
    }

    async componentDidMount() {
        this.setProgressBarColor();

        if(this.props.progresso){
            this.setState({
                min: this.props.progresso.min,
                max: this.props.progresso.max,
                progress: this.props.progresso.progress,
                label: this.props.progresso.label,
                permissionView: this.props.permissionView,
                loading: false
            });
        } else if(this.props.id) {
            await this.loadData();
        }
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.id && prevProps.id !== this.props.id) || prevProps.update !== this.props.update) {
            this.setState({ id: this.props.id }, async () => {
                if(!this.props.progresso)
                    await this.loadData();
            });
        }

        if (prevProps.backgroundColor !== this.props.backgroundColor || prevProps.color !== this.props.color) {
            this.setProgressBarColor();
        }

        if(this.props.progresso && prevProps.progresso !== this.props.progresso){
            this.setState({
                min: this.props.progresso.min,
                max: this.props.progresso.max,
                progress: this.props.progresso.progress,
                label: this.props.progresso.label,
                loading: false
            });
        }
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) {
                inner.style.backgroundColor = this.props.backgroundColor || Colors.dark;
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });

        switch (this.state.tipo) {
            case 'planoAcao':
                await this.loadPlanoAcaoProgress();
                break;
            case 'projeto':
                await this.loadProjetoProgress();
                break;
            case 'objetivo':
                await this.LoadObjetivoProgress();
                break;
            case 'mapaEstrategico':
                await this.LoadMapaProgress();
                break;
            default:
                break;
        }
    }

    async loadPlanoAcaoProgress() {

        let response = await Sig.request('GET', 'planoAcao/getProgress', { codigo: this.state.id });
        if (response.status === 200) {
            this.setState({
                min: response.min,
                max: response.max,
                progress: response.progress,
                label: response.label,
                permissionView: response.permissionView,
                loading: false
            }, () => {
                this.setProgressBarColor();
            });

        } else {
            this.setState({ loading: false }, () => {
                this.setProgressBarColor();
            });
        }

    }

    async loadProjetoProgress() {

        let response = await Sig.request('GET', 'pe/projeto/getProgress', { id: this.state.id });
        if (response.status === 200) {
            this.setState({
                min: response.min,
                max: response.max,
                progress: response.progress,
                label: response.label,
                permissionView: response.permissionView,
                loading: false
            }, () => {
                this.setProgressBarColor();
            });

        } else {
            this.setState({ loading: false }, () => {
                this.setProgressBarColor();
            });
        }

    }

    async LoadObjetivoProgress() {

        let response = await Sig.request('GET', 'pe/mapa/getObjetivoProgress', { id: this.state.id });

        if (response.status === 200) {
            this.setState({
                min: response.min,
                max: response.max,
                progress: response.progress,
                label: response.label,
                permissionView: response.permissionView,
                loading: false
            }, () => {
                this.setProgressBarColor();
            });

        } else {
            this.setState({ loading: false }, () => {
                this.setProgressBarColor();
            });
        }

    }

    async LoadMapaProgress() {

        let response = await Sig.request('GET', 'pe/mapa/getMapaProgress', { id: this.state.id });

        if (response.status === 200) {
            this.setState({
                min: response.min,
                max: response.max,
                progress: response.progress,
                label: response.label,
                permissionView: response.permissionView,
                loading: false
            }, () => {
                this.setProgressBarColor();
            });

        } else {
            this.setState({ loading: false }, () => {
                this.setProgressBarColor();
            });
        }

    }

    nonPermissionView() {
        return (
            <div style={{padding: 5, width: '100%', ...this.props.style}}>
                <div style={{ height: this.props.style?.height || 26, width: '100%', borderRadius: 8, backgroundColor: Colors.homePage.lightGrey, ...EssentialStyle.rowFlexStart, padding: '0px 5px'}}>
                    <FontAwesomeIcon icon={faLock} style={{ color: Colors.homePage.grey, fontSize: 14, marginRight: 5 }} />
                </div>
            </div>
        )
    }

    render() {

        if (!this.state.permissionView) return this.nonPermissionView()

        let completeEfect = this.state.progress >= this.state.max && this.state.completeEfect;

        if (this.progressBar && this.state.progressBarHovered) {
            let width = Math.max(95, this.state.progress);
            this.progressBar.querySelector('.progress-bar').style.width = width + '%';
        } else if (this.progressBar && !this.state.progressBarHovered) {
            const progress = String(this.state.progress); // Converte para string
            const progressWithoutDots = progress.replace(/\./g, ''); // Remove todos os pontos
            const characterCount = progressWithoutDots.length; // Conta os caracteres restantes

            this.progressBar.querySelector('.progress-bar').style.minWidth = 'calc(15px + (10px * ' + characterCount + '))';
            this.progressBar.querySelector('.progress-bar').style.width = 'calc(' + this.state.progress + '%)';
        }

        let label = this.state.progressBarHovered ?
            (<div style={{ paddingLeft: 4, paddingRight: 4, color: this.props.color || Colors.white }}>{this.state.label}</div>)
            : (<div style={{ paddingLeft: 4, paddingRight: 4, color: this.props.color || Colors.white }}>{completeEfect ? this.state.completeEfect : `${this.state.progress}%`}</div>);

        return (
            <div
                style={{ ...this.props.style }}
                onMouseEnter={() => this.setState({ progressBarHovered: this.state.label ? true : false })}
                onMouseLeave={() => this.setState({ progressBarHovered: false })}
            >
                <ProgressBar
                    min={this.state.min}
                    max={this.state.max}
                    animated={this.state.loading}
                    ref={ref => this.progressBar = ref}
                    now={this.state.progress}
                    label={<span className={completeEfect ? 'progress-bar-text' : ''}>{label}</span>}
                    style={{ height: this.props.style?.height || 26 }}
                    className={completeEfect ? 'progress-bar-100' : ''}
                />
            </div>
        );
    }

}
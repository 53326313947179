import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faHandshake, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import { toast } from "react-toastify";
import EssentialStyle from "../../style/EssentialStyle";
import AddPlanoAcao from "../../components/forms/planoAcao/add/AddPlanoAcao";
import { Collapse, Form } from "react-bootstrap";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import PlanoAcaoCard from "../../components/modules/planoAcao/PlanoAcaoCard/PlanoAcaoCard";
import DefaultLoader from "../../components/tools/DefaultLoader";
import Sig from "../../api/Sig";
import PlanoAcaoListSearch from "../../components/modules/planoAcao/PlanoAcaoListSearch";
import LayoutHelper from "../../helper/LayoutHelper";
import PlanoAcaoRelatorio from "../../components/modules/planoAcao/PlanoAcaoRelatorio/PlanoAcaoRelatorio";
import CustomTooltip from "../../components/tools/CustomTooltip";
import Tabs from "../../components/tools/Tabs";
import moment from "moment";

const searchableFields = ['nome'];

export default class PlanoAcaoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingHeader: true,
            showWizard: false,
            showEdit: false,
            advancedFilter: false,
            editId: null,
            canCreate: false,
            data: [],
            filter: {},
            page: 1,
            hasMore: true,
            firstLoad: true,
            headerSize: 0,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            activeTab: 'lista',
            parceirosChecked: false,
            showParceiros: false,
        }
        this.headerRef = React.createRef();
        this.interval = null;
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        if (document.querySelector('.listPlanos')) {
            document.querySelector('.listPlanos').addEventListener('scroll', this.handleScroll);
        }
        this.interval = setInterval(this.updateHeaderSize, 10);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        if (document.querySelector('.listPlanos')) {
            document.querySelector('.listPlanos').removeEventListener('scroll', this.handleScroll);
        }
    }

    verifyScreen = () => {
        if (this.state.isSmallScreen == LayoutHelper.isSmallScreen()) return;
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
            this.updateHeaderSize();
            this.setState({isSmallScreen: LayoutHelper.isSmallScreen()}, () => {
                if (this.state.showFilters && this.state.isSmallScreen){
                    this.setState({ showFilters: false });
                }
            });
        });
    }

    updateHeaderSize = () => {
        if (this.headerRef.current) {
            this.setState({ headerSize: this.headerRef.current.clientHeight });
            if (this.interval) clearInterval(this.interval);
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const tipo = queryParams.get('tipo');

                if (tipo && (tipo == "novo" || tipo == "editar")) {
                    this.setState({ showWizard: true });
                } else {
                    await this.getConfigColaborador();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleScroll = () => {
        const listPlanosDiv = document.querySelector('.listPlanos');
        if (listPlanosDiv) {
            if (listPlanosDiv.scrollTop + listPlanosDiv.clientHeight >= listPlanosDiv.scrollHeight) {
                if (!this.state.loading && this.state.hasMore && this.state.activeTab === 'lista') {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async getConfigColaborador() {
        let req = await Sig.request('GET', 'config/colaborador/getPlanoAcaoConfig');
        
        if (req.status != 200) {
            return toast.error("Erro ao carregar configuração.");
        }
        
        let headerReq = await Sig.request('GET', 'planoAcao/getHeaderConfig');

        if (headerReq.status != 200) {
            return toast.error("Erro ao carregar configuração.");
        }

        this.setState({ showParceiros: headerReq.showParceiros, activeTab: req.tipo_visualizacao, parceirosChecked: req.parceiro && headerReq.showParceiros }, () => {
            if(this.state.activeTab == 'relatorio') {
                this.setState({ filter: { tipoData: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }
                }, () => this.loadData(1));
            } else {
                this.loadData(1);
            }
        });
    }

    async setConfigColaborador() {
        let req = await Sig.request('POST', 'config/colaborador/setPlanoAcaoConfig', { tipo_visualizacao: this.state.activeTab, parceiro: this.state.parceirosChecked ? 1 : 0 });

        if (req.status != 200) {
            return toast.error("Erro ao salvar configuração.");
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false, loadingHeader: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true, loadingHeader: false });
            }, 200);
        }

        if (this.state.activeTab == 'relatorio') {
            var req = await Sig.request('POST', 'planoAcao/relatorio', { filter: JSON.stringify(this.state.filter), parceiro: this.state.parceirosChecked ? 1 : 0 });
            if (this.state.activeTab != 'relatorio') return;
        } else {
            var req = await Sig.request('POST', 'planoAcao/list', { page, limit: 10, filter: JSON.stringify(this.state.filter), parceiro: this.state.parceirosChecked ? 1 : 0 });
            if (this.state.activeTab != 'lista') return;
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            return toast.error("Erro ao carregar dados");
        }

        if (this.state.activeTab == 'lista') {
            if(req.data.length < 10)
                this.setState({ hasMore: false });
            else
                this.setState({ hasMore: true });
        }

        this.setState(prevState => ({
            data: page === 1 || this.state.activeTab == 'relatorio' ? req.data : [...prevState.data, ...req.data],
            loading: false,
            page
        }));

    }

    handleToggleExpand = (advancedFilter) => {
        this.setState({ advancedFilter: advancedFilter });
    }

    filter = async (filter, closeFilter = false) => {
        this.setState({ loading: true, filter }, () => {
            this.loadData(1);
            if (closeFilter) this.handleToggleExpand(false);
        });
    }

    handleSearch = async (text) => {
        this.setState({ loading: true, filter: { ...this.state.filter, nome: text } }, () => {
            this.loadData(1);
        });
    }

    countFilters = () => {
        let count = 0;
        for (let key in this.state.filter) {
            if ((this.state.filter[key] && !Array.isArray(this.state.filter[key]))) {
                if (key == 'dataInicio' && this.state.filter['dataFim']) {
                    count++;
                } else if (key == 'dataFim' || key == 'tipoData') {
                    continue;
                } else {
                    count++;
                }
            } else if (Array.isArray(this.state.filter[key]) && this.state.filter[key].length > 0) {
                count++;
            }
        }
        return count;
    }

    renderSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <CustomTooltip tooltip={'Visualizar Planos de Ação da minha empresa'}>
                    <FontAwesomeIcon icon={faBriefcase} />
                </CustomTooltip>
                <Form.Switch
                    readOnly={true}
                    type="switch"
                    checked={this.state.parceirosChecked}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <CustomTooltip tooltip={'Visualizar Planos de Ação de empresas parceiras'}>
                    <FontAwesomeIcon icon={faHandshake} />
                </CustomTooltip>
            </div>
        )
    }

    renderHeader = () => {
        const tabs = [
            { name: 'lista', title: 'Lista' }, 
            { name: 'relatorio', title: 'Relatório' }
        ];

        if ( !this.state.loadingHeader ) return (
            <div style={{width: "100%", top: 0, position: "sticky", zIndex: 5}}>
                <div ref={this.headerRef} style={{ ...EssentialStyle.rowFlexCenter, ...EssentialStyle.card, padding: 10, flexWrap: "wrap"}}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: "100%" }}>
                        <DefaultButton
                            onClick={() => this.setState({ showWizard: true })}
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            disabled={this.state.parceirosChecked}
                            color={Colors.success}
                            tooltip={this.state.parceirosChecked ? 'Acesse o ambiente do parceiro para adicionar planos de ação de parceiros' : ''}
                            tooltipPlacement={'bottom'}
                            title={this.state.isSmallScreen ? "" : "Plano de Ação"}
                        />
                        <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10 }}>
                            <DefaultTableSearch
                                handleSearch={this.handleSearch}
                                onEmpty={() => {
                                    if (this.state.activeTab != 'relatorio') {
                                        this.loadData(1)
                                    }
                                }}
                                toggleExpand={this.handleToggleExpand}
                                appliedFilters={this.countFilters()}
                                text={this.state.filter.nome}
                                advancedFilter={this.state.advancedFilter}
                                type={'planoAcao'}
                            />
                            {!this.state.isSmallScreen && this.state.showParceiros &&
                                <DefaultButton
                                    tooltipPlacement={'left'}
                                    leftIcon={this.renderSwitch()}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    loading={this.state.loading}
                                    onClick={() => { this.setState({ parceirosChecked: !this.state.parceirosChecked }, () => { this.loadData(1); this.setConfigColaborador(); }) }}
                                />
                            }
                            {!this.state.isSmallScreen && 
                                <Tabs 
                                    tabs={tabs} 
                                    activeTab={this.state.activeTab} 
                                    isSmallScreen={this.state.isSmallScreen} 
                                    onTabChange={this.handleTabChange}
                                    tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                                />
                            }   
                            <DefaultButton
                                style={{ width: 38, height: 38, marginRight: this.state.isSmallScreen ? 5 : 10 }}
                                fontSize={this.state.isSmallScreen ? 14 : "unset"}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                link={this.state.activeTab == 'lista' ? 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o' : 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#relatorios'}
                            />
                        </div>
                    </div>
                    {this.state.isSmallScreen &&
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                            <Tabs 
                                tabs={tabs} 
                                activeTab={this.state.activeTab} 
                                isSmallScreen={this.state.isSmallScreen} 
                                onTabChange={this.handleTabChange}
                                tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                            />
                            {this.state.showParceiros &&
                                <DefaultButton
                                    tooltipPlacement={'left'}
                                    leftIcon={this.renderSwitch()}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    loading={this.state.loading}
                                    onClick={() => { this.setState({ parceirosChecked: !this.state.parceirosChecked }, () => { this.loadData(1); }) }}
                                />
                            }
                        </div>
                    }
                </div>
                <Collapse in={this.state.advancedFilter}>
                    <div style={{ 
                            position: "absolute", 
                            zIndex: 5, 
                            width: "100%", 
                        }}
                    >
                        <PlanoAcaoListSearch
                            filterCallback={this.filter}
                            isSmallScreen={this.state.isSmallScreen}
                            type={this.state.activeTab}
                            nome={this.state.filter.nome}
                            advancedFilter={this.state.advancedFilter}
                            parceirosChecked={this.state.parceirosChecked}
                        />
                    </div>
                </Collapse>
            </div>
        );
    }

    renderPlanoAcaoCard(item, index) {
        return <PlanoAcaoCard 
            key={item.id} 
            plano={item} 
            isSmallScreen={this.state.isSmallScreen}
            parceirosChecked={this.state.parceirosChecked} 
            onClick={(plano, novaAba = false) => { 
                window.parent.postMessage({ type: novaAba ? '_blank' :'navigate', url: `/planoAcao/detalhar/${plano.codigo}` }, '*'); 
            }} 
        />
    }

    handleTabChange = (tab) => {
        if (this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name, loading: true, hasMore: tab.name == 'lista' ? true : false, firstLoad: true, page: 1, data: [] }, () => {
            this.setConfigColaborador();
        });
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return (
            <div className={'listPlanos'} style={{maxHeight: `calc(100vh - ${this.state.headerSize}px - 14px)`, overflowY: 'auto', width: "calc(100% + 7)", margin: '0px -7px', padding: '0px 7px'}}>
                {this.state.data.map((item, index) => this.renderPlanoAcaoCard(item, index))}
                {this.state.loading && <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}><DefaultLoader /></div>}
                {this.state.isSmallScreen && <div style={{ height: '7rem', width: '100%', marginTop: 10 }}/>}
            </div>
        );
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 85, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-ac%C3%A3o"} />
                <h5>Até o momento, sua empresa ainda não criou nenhum <strong>Plano de Ação</strong> ou <strong>Plano de desenvolvimento individual</strong>.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton
                    onClick={() => this.setState({ showWizard: true })}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    title={this.state.isSmallScreen ? "" : "Plano de Ação"}
                />
            </div>
        )
    }

    addCallback = async () => {
        this.setState({ editId: null, showWizard: false, showEdit: false }, this.loadData);
    }

    editCallback = async (id) => {
        this.setState({ editId: id }, () => this.setState({ showEdit: true }));
    }

    render() {
        if (this.state.showWizard) return <AddPlanoAcao addCallback={this.addCallback} />
        if (this.state.showEdit) return null; //pode usar até o mesmo addplano mas com um middleware no forms/planoAcao/edit, tu que sabe

        return (
            <div style={{padding: 7}}>
                {this.renderHeader()}
                {this.state.activeTab == 'lista' ? this.renderList() : <PlanoAcaoRelatorio planos={this.state.data} loading={this.state.loading} isSmallScreen={this.state.isSmallScreen} headerSize={this.state.headerSize} parceiro={this.state.parceirosChecked} />}
            </div>
        );
    }
}
import React from "react";
import { forwardRef } from 'react'
import Sig from "../../../../../api/Sig";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultButton from "../../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import Modal from "../../../../tools/Modal";
import DefaultLoader from "../../../../tools/DefaultLoader";
import DataHelper from "../../../../../helper/DataHelper";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import { toast } from "react-toastify";

import './ModalVincularProjetoMapa.css';

export default class ModalVincularProjetoMapa extends React.Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            selectedMapa: null,
            selectedObjetivo: null,
            selectedIniciativa: null,
            mapasUser: [],
            objetivos: [],
            iniciativas: [],
            disabledObjetivo: false,
            disabledIniciativa: false,
            success: false,
        };
    }

    async componentDidMount() {
        this.loadMapas();
    }

    loadMapas = async () => {

        this.setState({loadingMapa: true});

        let { mapasUser, preSelectedMapa } = await Sig.request('GET', 'pe/mapa/getMapaUser', { mapaId: null, select: true });
        
        let selectedMapa = preSelectedMapa

        if (!preSelectedMapa && mapasUser.length > 0) {
            selectedMapa = mapasUser[0];
        }

        this.setState({ mapasUser, selectedMapa, loadingMapa: false }, () => {
            this.state.selectedMapa != null && this.loadObjetivos();
        });
    }

    loadObjetivos = async () => {
        this.setState({selectedObjetivo: null, selectedIniciativa: null, loadingObjetivo: true})

        let { objetivos } = await Sig.request('GET', 'pe/mapa/getMapaObjetivosUser', { id: this.state.selectedMapa.value, objetivoHasIniciativa: true });
        
        objetivos = Object.values(objetivos);
        objetivos = objetivos.map(objetivo => {
            return {value: objetivo.id, label: DataHelper.removeHtmlTags(objetivo.valor)};
        });

        let selectedObjetivo = null;
        if (objetivos.length == 1) {
            selectedObjetivo = objetivos[0];
        }

        this.setState({ objetivos, selectedObjetivo, loadObjetivos: false }, () => {
            if (objetivos.length == 1) {
                this.loadIniciativas();
            }
        });
    }

    loadIniciativas = async () => {
        this.setState({ selectedIniciativa: null, loadIniciativas: true });

        let { iniciativas } = await Sig.request('POST', 'pe/projeto/getIniciativasObjetivo', { id_objetivo: this.state.selectedObjetivo.value});
        iniciativas = DataHelper.formatSelectData(iniciativas, 'id', 'nome');

        let selectedIniciativa = null;
        if (iniciativas.length == 1) {
            selectedIniciativa = iniciativas[0];
        }

        this.setState({iniciativas, selectedIniciativa, loadIniciativas: false});
    }

    vincularProjetoMapa = async () => {

        this.setState({loadingMapa: true});

        if (!this.props.idProjeto || !this.state.selectedIniciativa) {
            toast.error('Projeto ou Iniciativa não selecionados');
            this.setState({loadingMapa: false});
            return;
        }

        let request = await Sig.request('POST', 'pe/projeto/vincularProjetoMapa', {id_projeto: this.props.idProjeto, id_iniciativa: this.state.selectedIniciativa.value});
        
        if (request.projeto) {

            this.setState({success: true}, () => {
                setTimeout(() => {
                    this.props.reloadPage();
                    this.props.closeModal();
                }, 2000);
            });
            
        } else {
            this.setState({loadingMapa: false});
        }
    }

    renderSuccess() {
        return (
            <div className="ModalVincularProjetoMapa success-container">
                <div className="success-title">
                    <FontAwesomeIcon icon={faLink} style={{ marginRight: 10 }} />
                    Projeto Vinculado com Sucesso
                </div>
                <div className="success-message">
                    Seu projeto foi vinculado com sucesso ao mapa estratégico.
                </div>
            </div>
        )
    }
    
    renderContentModal() {
        return (
            <>
                {this.renderTitle()}
                {this.state.loadingMapa ? this.renderloadingMapa() : this.renderBody()}
                {this.renderFooter()}
            </>
        )
    }

    renderTitle() {
        return (
            <div style={{ fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10 }}>
                <FontAwesomeIcon icon={faLink} style={{ marginRight: 10 }} />
                Vincular Projeto com Mapa Estratégico Existente <span style={{ fontSize: 22, fontWeight: 400 }}>{DataHelper.removeHtmlAndReplaceListItems(this.state.objetivo?.valor)}</span>
            </div>
        )
    }

    renderBody() {

        let disabledObjetivo = this.state.selectedMapa == null;
        let disabledIniciativa = disabledObjetivo || this.state.selectedObjetivo == null || this.state.loadingMapa || this.state.loadingObjetivo;

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, margin: 0, padding: 0, backgroundColor: 'transparent', boxShadow: 'none', height: '20rem', width: '100%' }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    padding: 7,
                }}>
                    <Form style={{ width: 'calc(100% - 14px)' }}>
                        <Row>
                            <Form.Group as={Col} md={12} xxl={6} className="mb-3">
                                <Form.Label>Mapa Estratégico</Form.Label>
                                <Select
                                    options={this.state.mapasUser}
                                    placeholder={'Selecione o Mapa Estratégico'} 
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    value={this.state.selectedMapa}
                                    menuPortalTarget={this.modalRef.current}
                                    onChange={(value) => { this.setState({ selectedMapa: value }, () => {this.loadObjetivos()}) }}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} xxl={6} className="mb-3">
                                <Form.Label>Objetivo</Form.Label>
                                <Select
                                    idDisabled={disabledObjetivo}
                                    options={this.state.objetivos}
                                    placeholder={'Selecione um Objetivo'} 
                                    noOptionsMessage={() => {return 'Nenhum Objetivo com Iniciativa encontrado'}}
                                    value={this.state.selectedObjetivo}
                                    menuPortalTarget={this.modalRef.current}
                                    onChange={(value) => { this.setState({ selectedObjetivo: value }, () => {this.loadIniciativas()}) }}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                                <Form.Label>Iniciativa</Form.Label>
                                <Select
                                    idDisabled={disabledIniciativa}
                                    options={this.state.iniciativas}
                                    placeholder={'Selecione uma Iniciativa'} 
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    value={this.state.selectedIniciativa}
                                    menuPortalTarget={this.modalRef.current}
                                    onChange={(value) => { this.setState({ selectedIniciativa: value }) }}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </div>
        )
    }

    renderFooter() {
        return (

            <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', height: 38, marginTop: 10, gap: 10 }}>
                <div style={{flex: 1, height: '100%'}}>
                    
                </div>

                <DefaultButton
                    title={'Vincular'}
                    disabled={this.state.selectedIniciativa == null}
                    leftIcon={<FontAwesomeIcon icon={faLink} />}
                    color={Colors.success}
                    textColor={Colors.white}
                    onClick={() => { this.vincularProjetoMapa() }}
                />

                <DefaultButton
                    title={'Fechar'}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal()}
                />
            </div>
        )
    }

    renderloadingMapa() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '20rem' }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '70vw' }} ref={this.state.modalRef}>
                {this.state.success ? this.renderSuccess() : this.renderContentModal()}
            </Modal>
        )
    }
}
import React, { forwardRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faLink, faTimesCircle, faQuestionCircle, faClipboard, faUndo, faMagic } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import Sig from "../../../../api/Sig";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import AddTag from "../../../tools/AddTag";
import UploadFiles from "../../../tools/UploadFiles";
import DatePicker from 'react-datepicker';
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";
import SessionHelper from "../../../../helper/SessionHelper";
import moment from "moment";

import './AddPlanoAcao.css';
import EllipsisText from "../../../tools/EllipsisText";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";

const GMR = 1;
const PCO = 3;
const AC = 4;
const ANALISE = 5;
const FEEDBACK = 6;
const MARCO = 7;
const DIAGNOSTICO = 8;
const DIAGNOSTICO_NODE = 9;

const TIPO_MELHORIA = 0;
const TIPO_CORRETIVA = 1;
const TIPO_PREVENTIVA = 2;
const TIPO_PDI = 3;
const TIPO_MARCO = 4;

const tipoPlanoMap = {
    [AC]: TIPO_PDI,
    [FEEDBACK]: TIPO_PDI,
    7: TIPO_MARCO
};

export default class AddPlanoAcao extends React.Component {
    state = {
        step: 1,
        loading: true,
        loadingGpt: false,
        ignoreDescription: false,
        loadingVinculo: false,
        loadingDescription: false,
        listLatestDescription: [],
        nomesGenericos: [],
        id: null,
        code: null,
        modo: "novo",
        lang: 1,
        isSmallScreen: LayoutHelper.isSmallScreen(),

        // form one
        name: null,
        type: null,
        responsable: null,
        evaluator: null,
        participants: [],
        tags: [],
        tipoProgresso: null,

        // form two
        vinculos: [],
        nonCompliance: null,
        rootCause: null,
        description: null,
        files: [],

        // controls
        viewSelectVinculo: false,
        colaboradores: [],
        responsables: [],
        tiposPlano: [],
        tiposVinculos: [],
        tiposProgresso: [],

        // processos
        processosGmr: [],
        processosPesquisas: [],
        processosAnalises: [],
        processosGd: [],
        processosAvaliacao: [],
    }

    async componentDidMount() {
        this.handleUrlParams();
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const tipo = queryParams.get('tipo');

                if (tipo && (tipo == "novo" || tipo == "editar")) {
                    await this.loadData(true, queryParams);
                } else {
                    await this.loadData();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData(getParams = false, queryParams = null) {

        this.setState({ loading: true });

        if (getParams && queryParams && queryParams.get('tipo') == 'editar') {
            this.setState({ modo: "editar" });
            await this.loadEditar(getParams, queryParams);
        } else {
            this.setState({ modo: "novo" });
            await this.loadNovo(getParams, queryParams);
        }

        this.setState({ loading: false });
    }

    async loadNovo(getParams = false, queryParams = null) {

        let temVinculoAC = false, tipoPlano = this.state.type;

        // seta a URL para novo se não tiver parâmetros
        window.parent.postMessage({ type: 'add_url_param', param: 'tipo', value: 'novo' }, '*');

        // Adiciona o vinculo da URL
        if (getParams && queryParams && queryParams.get('tipo') == 'novo' && queryParams.get('modulo')) {
            let tipoVinculo = queryParams.get('modulo');

            tipoPlano = tipoPlanoMap[tipoVinculo] || null;
            temVinculoAC = tipoVinculo == AC;

            this.addVinculo(tipoVinculo, null, null, () => { this.getVinculoUrlParams(tipoVinculo, queryParams) });
        }

        // Busca de informaçõa para criar o plano de ação (possiveis vinculos, tipos de planos, colaboradores)
        let infos = await Sig.request('GET', 'planoAcao/getInfosCriarPlanoAcao', { tipo: tipoPlano });
        let progresso = await Sig.request('GET', 'planoAcao/getTipoProgresso', { codigo: null });
        let tiposVinculos = await PlanoAcaoHelper.getPossiveisVinculos(tipoPlano, temVinculoAC);

        // Faz o pré-preenchimento dos campos de acordo com o tipo de vinculo na URL
        let name = '', responsable = null, responsables = null, evaluator = null, type = null, tiposProgresso = progresso.tipos, tipoProgresso = parseInt(progresso.default), participants = [];

        if (infos.participanteTerceiro) {
            participants.push({ value: infos.participanteTerceiro?.id, label: infos.participanteTerceiro?.nome });
        } else {
            responsable = {value: infos.user.id, label: infos.user.nome};
        }

        if (getParams && queryParams && queryParams.get('tipo') == 'novo' && (queryParams.get('modulo') ? true : false)) {
            
            let tipoVinculo = queryParams.get('modulo');

            if (tipoVinculo == AC && queryParams.get('id_colaborador')) {
                responsable = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == queryParams.get('id_colaborador'));
            
            } else if (tipoVinculo == ANALISE && queryParams.get('acao')) {
                let data = await Sig.request('GET', 'planoAcao/getAcaoAnalise', { id_analise_problema_acao: queryParams.get('acao') });
                name = data.descricao;

            } else if (tipoVinculo == FEEDBACK && queryParams.get('id_assunto')) {
                let data = await Sig.request('GET', 'planoAcao/getFeedbacksVinculo', { id_assunto: queryParams.get('id_assunto') });
                responsable = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == data.responsavel);
                evaluator = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == data.avaliador);
    
            } else if (tipoVinculo == MARCO && queryParams.get('marco')) {
                let data = await PlanoAcaoHelper.getProjetoMarco(queryParams.get('marco'));
                evaluator = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == parseInt(data.projeto.id_responsavel));
                name = data.marco.descricao;
                tipoProgresso = parseInt(data.projeto.tipo_progresso);
                
            } else if (tipoVinculo == DIAGNOSTICO && queryParams.get('recomendacao')) {
                let dataRecomendacao = await PlanoAcaoHelper.getRecomendacaoRelatorio(queryParams.get('recomendacao'));
                name = DataHelper.removeHtmlAndReplaceListItems(dataRecomendacao.recomendacao?.relatorioRecomendacao?.descricao);
            }

        } else if (infos.rascunho != null) {
            let name = infos.rascunho.nome;
            responsable = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == infos.rascunho.responsavel?.id) ?? responsable;
            type = DataHelper.formatSelectData(infos.tipos, "id", "nome").find((item) => item.value == infos.rascunho.tipo);
            let evaluator = DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((item) => item.value == infos.rascunho.superior?.id) ?? null;
            let participants = infos.rascunho.participantes.map((item) => DataHelper.formatSelectData(infos.colaboradores, 'id', 'nome').find((colaborador) => colaborador.value == item.id)) ?? null;
            let tags = infos.rascunho.tags.map((item) => { return { value: item.id, name: item.nome } });
            this.setState({ name, type, evaluator, participants, tags });
        }

        this.setState({ type: type == null ? DataHelper.formatSelectData(infos.tipos, "id", "nome")[0] : type });
        this.setState({ 
            name, 
            colaboradores: infos.colaboradores, 
            responsables,
            responsable, 
            evaluator,
            participants, 
            tiposPlano: infos.tipos, 
            tiposVinculos, 
            nomesGenericos: infos.nomesGenericos,
            tiposProgresso,
            tipoProgresso
        }, () => {this.setState({ loading: false })});
    }

    async loadEditar(getParams = false, queryParams = null) {

        let codigo = queryParams.get('codigo');

        // Busca Informações do Plano de Ação
        let permissions = await Sig.request('GET', 'planoAcao/getPermissoes', { codigo });
        let progresso = await Sig.request('GET', 'planoAcao/getTipoProgresso', { codigo });
        let planoAcao = await Sig.request('POST', 'planoAcao/getPlanoAcao', { codigo });
        planoAcao = planoAcao.data;

        // Verifica se o usuário tem permissão para editar o plano de ação
        if (!permissions.canEdit) {
            toast.error('Você não tem permissão para editar este plano de ação.');
            return window.parent.postMessage({ type: 'navigate', url: `/planoAcao` }, '*');
        }

        let infos = await Sig.request('GET', 'planoAcao/getInfosCriarPlanoAcao', { tipo: planoAcao.tipo });
        let tiposVinculos = await PlanoAcaoHelper.getPossiveisVinculos(planoAcao.tipo, planoAcao.vinculos.find((item) => item.tipo == AC) ? true : false);

        let type = DataHelper.formatSelectData(infos.tipos, "id", "nome").find((item) => item.value == planoAcao.tipo);
        let colaboradores = infos.colaboradores;
        let nomesGenericos = infos.nomesGenericos;
        let responsable = planoAcao.responsavel?.id ? { value: planoAcao.responsavel?.id, label: planoAcao.responsavel?.nome } : null;
        let evaluator = planoAcao.superior?.id ? { value: planoAcao.superior?.id, label: planoAcao.superior?.nome } : null;
        let participants = planoAcao.participantes.map((item) => { return { value: item.id, label: item.nome } }) ?? null;
        let tags = planoAcao.tags.map((item) => { return { value: item.id, name: item.nome } });
        let rootCause = planoAcao.causa_raiz;
        let nonCompliance = planoAcao.nao_conformidade;
        let description = planoAcao.descricao;
        let tiposProgresso = progresso.tipos;
        let tipoProgresso = parseInt(progresso.atual);

        // Carrega os vinculos do plano de ação
        for (const vinculo of planoAcao.vinculos) {
            await new Promise((resolve) => {
                this.addVinculo(vinculo.tipo, vinculo.id, codigo, () => {
                    resolve();
                });
            });

            await new Promise((resolve) => {
                this.configureVinculoEdicao(vinculo, responsable.value, () => {
                    resolve();
                });
            });

        }

        this.setState({
            id: planoAcao.id, code: planoAcao.codigo,
            name: planoAcao.nome, type, tags, responsable, evaluator, participants,
            colaboradores, tiposPlano: infos.tipos, tiposVinculos, nomesGenericos,
            tiposProgresso, tipoProgresso,
            nonCompliance, rootCause, description,
        }, () => { this.setState({ loading: false }) });

    }

    async getVinculoUrlParams(tipoVinculo, queryParams) {

        switch (parseInt(tipoVinculo)) {
            case GMR:
                let id_processo = queryParams.get('id_processo');
                let id_gmr_painel = queryParams.get('id_gmr_painel');
                let id_indicador = queryParams.get('id_indicador');
                let mes = queryParams.get('mes');
                let ano = queryParams.get('ano');
                let data_inicio = mes && ano ? moment(`${ano}-${mes}-02`, 'YYYY-MM-DD').toDate() : null;

                if (!id_processo || !id_gmr_painel || !id_indicador || !data_inicio) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let vinculo = this.state.vinculos[0];
                vinculo.id_processo = DataHelper.formatSelectData(this.state.processosGmr, 'id', 'nome').find((item) => item.value == id_processo);
                vinculo.data_inicio = data_inicio;
                this.setState({ vinculos: [vinculo] });

                await this.loadGmrPaineis(vinculo);

                vinculo = this.state.vinculos[0];
                vinculo.id_gmr_painel = DataHelper.formatSelectData(vinculo.gmrPaineisDisponiveis, 'id', 'nome').find((item) => item.value == id_gmr_painel);
                this.setState({ vinculos: [vinculo] });

                await this.getIndicadoresPainel(vinculo);

                vinculo = this.state.vinculos[0];
                vinculo.id_indicador = DataHelper.formatSelectData(vinculo.indicadoresDisponiveis, 'id', 'nome').find((item) => item.value == id_indicador);
                this.setState({ vinculos: [vinculo] });

                await this.getResultIndicadorPeriodo(vinculo);
                break;
            case PCO:

                let pcoProcesso = queryParams.get('id_processo');
                let pcoPergunta = queryParams.get('id_pco_pergunta');

                if (!pcoProcesso || !pcoPergunta) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let vinculoPco = this.state.vinculos[0];
                vinculoPco.id_processo = DataHelper.formatSelectData(this.state.processosPesquisas, 'id', 'nome').find((item) => item.value == pcoProcesso);
                this.setState({ vinculos: [vinculoPco] });

                await this.loadPergunta(vinculoPco);

                vinculoPco = this.state.vinculos[0];

                vinculoPco.id_pco_pergunta = DataHelper.formatSelectData(vinculoPco.perguntas, 'id', 'descricao').find((item) => item.value == pcoPergunta);
                this.setState({ vinculos: [vinculoPco] });

                await this.getResultadoPco(vinculoPco);

                break;
            case AC:
                if (!queryParams.get('id_periodo_avaliacao') || !queryParams.get('id_colaborador')) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let vinculoAc = this.state.vinculos[0];
                vinculoAc.disableDelete = true;
                vinculoAc.id_processo = DataHelper.formatSelectData(this.state.processosGd, 'id', 'nome').find((item) => item.value == queryParams.get('id_periodo_avaliacao'));
                this.setState({ vinculos: [vinculoAc] });

                await this.getPilaresPeriodoAvaliacao(this.state.vinculos[0], queryParams.get('id_colaborador'));

                break;
            case ANALISE:
                if (!queryParams.get('id_processo')) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let vinculoAnalise = this.state.vinculos[0];
                vinculoAnalise.id_processo_analise = DataHelper.formatSelectData(this.state.processosAnalises, 'id', 'nome').find((item) => item.value == queryParams.get('id_processo'));
                this.setState({ vinculos: [vinculoAnalise] }, () => {
                    this.getDescricaoAnalise(vinculoAnalise);
                });

                await this.getAcoes(vinculoAnalise);
                
                if (queryParams.get('acao')) {
                    vinculoAnalise = this.state.vinculos[0];
                    vinculoAnalise.id_analise_problema_acao = DataHelper.formatSelectData(vinculoAnalise.acoes, 'id', 'descricao').find((item) => item.value == queryParams.get('acao'));
                    this.setState({ vinculos: [vinculoAnalise] });
                }
                

                break;
            case FEEDBACK:
                if (!queryParams.get('id_assunto')) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let vinculoFeedback = this.state.vinculos[0];
                vinculoFeedback.id_assunto = queryParams.get('id_assunto');
                this.setState({ vinculos: [vinculoFeedback] });

                await this.getAssuntoFeedbacks(this.state.vinculos[0]);

                break;
            case MARCO:
                if (!queryParams.get('marco')) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do Marco.');
                    break;
                }

                let vinculoMarco = this.state.vinculos[0];
                let data = await PlanoAcaoHelper.getProjetoMarco(queryParams.get('marco'));

                if (data.marco == null || data.projeto == null) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do Marco.');
                    break;

                }
                
                vinculoMarco.id_pe_projeto = { value: data.projeto.id, label: data.projeto.nome };
                vinculoMarco.id_pe_projeto_marco = { value: data.marco.id, label: data.marco.descricao };
                this.setState({ vinculos: [vinculoMarco] });

                break;
            case DIAGNOSTICO:
                if (!queryParams.get('recomendacao') && (!queryParams.get('node') || !queryParams.get('avaliacao'))) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do Diagnostico.');
                    break;
                }

                
                let vinculoDiagnostico = this.state.vinculos[0];
                let dataDiagnostico = await PlanoAcaoHelper.getRecomendacaoRelatorio(queryParams.get('recomendacao') || null, queryParams.get('node') || null, queryParams.get('avaliacao') || null);
                let colunas = await DiagnosticoHelper.getColunasQuestionarioDiagnosticoAvaliacao(dataDiagnostico.avaliacao.id, 'pt');
                let nodes = await DiagnosticoHelper.getItensColuna(dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id_coluna, 'pt');
                
                if (!dataDiagnostico || !colunas || !nodes) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do Diagnóstico.');
                    break;
                }

                vinculoDiagnostico.disableAlter = true;
                vinculoDiagnostico.disableDelete = true;

                vinculoDiagnostico.colunas = colunas;
                vinculoDiagnostico.nodes = nodes;
                
                vinculoDiagnostico.id_diagnostico_avaliacao = { value: dataDiagnostico.avaliacao.id, label: dataDiagnostico.avaliacao.nome };
                vinculoDiagnostico.id_coluna = { value: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id_coluna, label: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.coluna };
                vinculoDiagnostico.id_diagnostico_periodo_questionario_node = { value: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id, label: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.descricao };
                vinculoDiagnostico.resultado = dataDiagnostico.resultados;

                vinculoDiagnostico.id_relatorio_recomendacao = {value: dataDiagnostico.recomendacao?.relatorioRecomendacao?.id ?? null, label: dataDiagnostico.recomendacao?.relatorioRecomendacao?.descricao ?? '<p>Não existente</p>'};
                vinculoDiagnostico.id_diagnostico_avaliacao = {value: dataDiagnostico?.avaliacao?.id || 1, label: dataDiagnostico?.avaliacao?.nome || "Não Encontrada"};

                this.setState({ vinculos: [vinculoDiagnostico] });

                break;
            default:
                toast.error('Tipo de vínculo não suportado.');
                break;
        }
    }

    async configureVinculoEdicao(vinculo, id_colaborador, callback = () => { }) {

        let idNovoVinculo = vinculo.id;

        switch (parseInt(vinculo.tipo)) {
            case GMR:
                let id_processo = vinculo.id_processo;
                let id_gmr_painel = vinculo.id_gmr_painel;
                let id_indicador = vinculo.id_indicador;
                let inicio_mes = vinculo.inicio_mes;
                let inicio_ano = vinculo.inicio_ano;
                let data_inicio = inicio_mes && inicio_ano ? moment(`${inicio_ano}-${inicio_mes}-02`, 'YYYY-MM-DD').toDate() : null;
                let fim_mes = vinculo.fim_mes;
                let fim_ano = vinculo.fim_ano;
                let data_fim = fim_mes && fim_ano ? moment(`${inicio_ano}-${inicio_mes}-02`, 'YYYY-MM-DD').toDate() : null;
                let meta = vinculo.meta;

                let index = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);

                if (!id_processo || !id_gmr_painel || !id_indicador || !data_inicio || !data_fim || !meta) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    callback();
                    break;
                }

                const newVinculos = [...this.state.vinculos];
                newVinculos[index] = {
                    ...newVinculos[index],
                    id_processo: DataHelper.formatSelectData(this.state.processosGmr, 'id', 'nome').find((item) => item.value == id_processo) ?? null,
                    data_inicio, data_fim, meta
                };

                this.setState({
                    vinculos: newVinculos
                }, () => {

                    if (!DataHelper.formatSelectData(this.state.processosGmr, 'id', 'nome').find((item) => item.value == id_processo)) {
                        this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                        toast.error('Vinculo com Processo GMR não encontrado.');
                        callback();
                    }

                    this.loadGmrPaineis(this.state.vinculos[index], () => {

                        id_gmr_painel = DataHelper.formatSelectData(this.state.vinculos[index].gmrPaineisDisponiveis, 'id', 'nome').find((item) => item.value == id_gmr_painel);

                        if (!id_gmr_painel) {
                            this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                            toast.error('Vínculo com Painel não encontrado.');
                            callback();
                        }

                        const newVinculos = [...this.state.vinculos];
                        newVinculos[index] = {
                            ...newVinculos[index],
                            id_gmr_painel,
                        };

                        this.setState({
                            vinculos: newVinculos
                        }, () => {
                            this.getIndicadoresPainel(this.state.vinculos.find((item) => item.id == idNovoVinculo), () => {

                                id_indicador = DataHelper.formatSelectData(this.state.vinculos[index].indicadoresDisponiveis, 'id', 'nome').find((item) => item.value == id_indicador);

                                if (!id_indicador) {
                                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                                    toast.error('Vínculo com Indicador não encontrado.');
                                    callback();
                                }

                                const newVinculos = [...this.state.vinculos];
                                newVinculos[index] = {
                                    ...newVinculos[index],
                                    id_indicador,
                                };

                                this.setState({
                                    vinculos: newVinculos
                                }, () => {
                                    this.getResultIndicadorPeriodo(this.state.vinculos.find((item) => item.id == idNovoVinculo), () => {
                                        callback();
                                    });
                                });
                            });

                        });

                    });
                });

                break;
            case PCO:
                let pcoProcesso = vinculo.id_processo;
                let pcoPergunta = vinculo.id_pco_pergunta;

                if (!pcoProcesso || !pcoPergunta) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    callback();
                    break;
                }

                let indexPCO = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosPCO = [...this.state.vinculos];
                newVinculosPCO[indexPCO] = {
                    ...newVinculosPCO[indexPCO],
                    id_processo: DataHelper.formatSelectData(this.state.processosPesquisas, 'id', 'nome').find((item) => item.value == pcoProcesso)
                };

                this.setState({
                    vinculos: newVinculosPCO
                }, () => {
                    this.loadPergunta(this.state.vinculos[indexPCO], () => {

                        let newVinculosPCO = [...this.state.vinculos];
                        newVinculosPCO[indexPCO] = {
                            ...newVinculosPCO[indexPCO],
                            id_pco_pergunta: DataHelper.formatSelectData(newVinculosPCO[indexPCO].perguntas, 'id', 'descricao').find((item) => item.value == pcoPergunta),
                        };

                        this.setState({ vinculos: newVinculosPCO }, () => {
                            this.getResultadoPco(newVinculosPCO[indexPCO], () => {
                                callback();
                            });
                        });
                    });
                });

                break;
            case AC:
                let id_processoAC = vinculo.id_processo;
                let id_comportamentoAC = vinculo.id_comportamento;

                if (!id_processoAC || !id_comportamentoAC) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do vínculo AC.');
                    callback();
                    break;
                }

                let indexAC = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosAC = [...this.state.vinculos];
                newVinculosAC[indexAC] = {
                    ...newVinculosAC[indexAC],
                    id_processo: DataHelper.formatSelectData(this.state.processosGd, 'id', 'nome').find((item) => item.value == id_processoAC),
                }

                this.setState({ vinculos: newVinculosAC }, () => {
                    this.getPilaresPeriodoAvaliacao(this.state.vinculos[indexAC], id_colaborador, async () => {
                        let id_pilar = await this.getPilarComportamento(id_comportamentoAC);
                        let newVinculosAC = [...this.state.vinculos];
                        newVinculosAC[indexAC] = {
                            ...newVinculosAC[indexAC],
                            id_pilar: DataHelper.formatSelectData(newVinculosAC[indexAC].pilares, 'id', 'nome').find((item) => item.value == id_pilar),
                        }

                        this.setState({ vinculos: newVinculosAC }, () => {
                            this.getComportamentosPeriodoAvaliacao(newVinculosAC[indexAC], id_colaborador, () => {

                                let newVinculosAC = [...this.state.vinculos];
                                let id_comportamento = DataHelper.formatSelectData(this.state.vinculos[indexAC].comportamentos, 'id', 'nome').find((item) => item.value == id_comportamentoAC);
                                newVinculosAC[indexAC] = {
                                    ...newVinculosAC[indexAC],
                                    id_comportamento,
                                }

                                this.setState({ vinculos: newVinculosAC }, () => {
                                    this.getEstruturaComportamento(this.state.vinculos[indexAC], id_colaborador, () => {
                                        callback();
                                    });
                                });
                            });
                        });
                    });
                });

                break;
            case ANALISE:
                if (!vinculo.id_analise_problema_acao && !vinculo.id_processo_analise) {
                    this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    break;
                }

                let inicio_ano_analise = vinculo.inicio_ano;
                let inicio_mes_analise = vinculo.inicio_mes;
                let data_inicio_analise = inicio_mes_analise && inicio_ano_analise ? moment(`${inicio_ano_analise}-${inicio_mes_analise}-02`, 'YYYY-MM-DD').toDate() : null;

                let fim_ano_analise = vinculo.fim_ano;
                let fim_mes_analise = vinculo.fim_mes;
                let data_fim_analise = fim_mes_analise && fim_ano_analise ? moment(`${fim_ano_analise}-${fim_mes_analise}-02`, 'YYYY-MM-DD').toDate() : null;

                let indexAnalise = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosAnalise = [...this.state.vinculos];
                newVinculosAnalise[indexAnalise] = {
                    ...newVinculosAnalise[indexAnalise],
                    id_processo_analise: DataHelper.formatSelectData(this.state.processosAnalises, 'id', 'nome').find((item) => item.value == vinculo.id_processo_analise),
                    id_processo: vinculo.id_processo,
                    meta: vinculo.meta,
                    data_inicio: data_inicio_analise,
                    data_fim: data_fim_analise
                };

                this.setState({ vinculos: newVinculosAnalise }, () => {
                    if (this.state.vinculos[indexAnalise].id_processo_analise){
                        this.getAcoes(this.state.vinculos[indexAnalise], () => {

                            let newVinculosAnalise = [...this.state.vinculos];
                            newVinculosAnalise[indexAnalise] = {
                                ...newVinculosAnalise[indexAnalise],
                                id_analise_problema_acao: DataHelper.formatSelectData(this.state.vinculos[indexAnalise].acoes, 'id', 'descricao').find((item) => item.value == vinculo.id_analise_problema_acao),
                            }
    
                            this.setState({ vinculos: newVinculosAnalise }, () => {
                                callback();
                            });
                        });
                    } else {
                        this.setState({ vinculos: this.state.vinculos.slice(0, -1) });
                        toast.error('Erro ao carregar os parâmetros do vínculo.');
                        callback();
                    }
                });

                break;
            case FEEDBACK:

                if (!vinculo.id_assunto) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do vínculo.');
                    callback();
                    break;
                }

                let indexFeedback = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosFeedback = [...this.state.vinculos];

                newVinculosFeedback[indexFeedback] = { ...newVinculosFeedback[indexFeedback], id_assunto: vinculo.id_assunto };

                // Usar setState para substituir o array vinculos no estado com a cópia atualizada
                this.setState(
                    { vinculos: newVinculosFeedback },
                    async () => {
                        await this.getAssuntoFeedbacks(this.state.vinculos[indexFeedback]);
                        callback();
                    }
                );

                break;
            case MARCO:

                if (!vinculo.id_pe_projeto_marco) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do Marco.');
                    callback();
                    break;
                }

                let data = await PlanoAcaoHelper.getProjetoMarco(vinculo.id_pe_projeto_marco);
                let indexMarco = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosMarco = [...this.state.vinculos];

                newVinculosMarco[indexMarco] = { 
                    ...newVinculosMarco[indexMarco], 
                    id_pe_projeto: { value: data.projeto.id, label: data.projeto.nome }, 
                    id_pe_projeto_marco: { value: data.marco.id, label: data.marco.descricao }
                };

                this.setState(
                    { vinculos: newVinculosMarco },
                    () => {
                        callback();
                    }
                );

                break;
            case DIAGNOSTICO:

                if (!vinculo.id_diagnostico_periodo_questionario_node || !vinculo.id_diagnostico_avaliacao) {
                    this.setState({ vinculos: this.state.vinculos.filter(vinculo => vinculo.id !== idNovoVinculo) });
                    toast.error('Erro ao carregar os parâmetros do Marco.');
                    callback();
                    break;
                }

                let dataDiagnostico = await PlanoAcaoHelper.getRecomendacaoRelatorio(null, vinculo.id_diagnostico_periodo_questionario_node, vinculo.id_diagnostico_avaliacao);
                let colunas = await DiagnosticoHelper.getColunasQuestionarioDiagnosticoAvaliacao(vinculo.id_diagnostico_avaliacao, 'pt');
                let nodes = await DiagnosticoHelper.getItensColuna(dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id_coluna, 'pt');

                let indexDiagnosticoRecomendacao = this.state.vinculos.findIndex((item) => item.id == idNovoVinculo);
                let newVinculosDiagnosticoRecomendacao = [...this.state.vinculos];

                newVinculosDiagnosticoRecomendacao[indexDiagnosticoRecomendacao] = { 
                    ...newVinculosDiagnosticoRecomendacao[indexDiagnosticoRecomendacao],
                    disableAlter: false,
                    disableDelete: false,
                    colunas,
                    nodes,
                    id_diagnostico_avaliacao: { value: dataDiagnostico.avaliacao.id, label: dataDiagnostico.avaliacao.nome },
                    id_coluna: { value: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id_coluna, label: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.coluna },
                    id_diagnostico_periodo_questionario_node: { value: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.id, label: dataDiagnostico.tree?.[dataDiagnostico.tree.length - 1]?.descricao },
                    resultado: dataDiagnostico.resultados,
                    id_relatorio_recomendacao: {value: dataDiagnostico.recomendacao?.relatorioRecomendacao?.id ?? null, label: dataDiagnostico.recomendacao?.relatorioRecomendacao?.descricao ?? '<p>Não existente</p>'},
                    id_diagnostico_avaliacao: {value: dataDiagnostico?.avaliacao?.id || 1, label: dataDiagnostico?.avaliacao?.nome || "Não Encontrada"},
                };

                this.setState({ vinculos: newVinculosDiagnosticoRecomendacao }, () => { callback()});

                break
            default:
                toast.error('Tipo de vínculo não suportado.');
                callback();
                break;
        }
    }

    step() {

        if (this.state.step > 1) {
            this.setState({ step: this.state.step - 1 });
        } else if (this.state.modo == "novo") {
            window.parent.postMessage({ type: 'delete_url_params' }, '*');
            this.props.addCallback();
        } else if (this.state.modo == "editar") {
            window.parent.postMessage({ type: 'navigate', url: `planoAcao/detalhar/${this.state.code}` }, '*');
        }
    }

    nextStep() {
        if (this.state.step === 1) {
            if (!this.state.name || !this.state.type || !this.state.responsable || parseInt(this.state.tipoProgresso) === null) {
                toast.error('Preencha todos os campos obrigatórios!');
                return;
            } else {
                this.saveRascunho();
            }
        }
    }

    saveRascunho = async () => {

        this.setState({ loading: this.state.modo == "novo" ? "Salvando Rascunho..." : "" });

        let getFileSaved = this.getDadosPlanoAcao(true, false);

        if (this.state.modo == "novo") {

            let response = await Sig.request('POST', 'planoAcao/savePlanoAcao', { file: JSON.stringify(getFileSaved.fileSaved), rascunho: true, id: this.state.id });

            if (response.message) {
                this.setState({ id: response.id, code: response.codigo }, () => {
                    // toast.success("Rascunho Salvo!"); // optamos por não comunicar o cliente que está sendo salvo o rascunho;
                });
            }
        }

        this.setState({ step: this.state.step + 1, loading: false });
    }

    save = async () => {

        let { allFieldsValid, errors, fileSaved } = this.getDadosPlanoAcao(true, true);

        if (!allFieldsValid) {
            toast.error(<div dangerouslySetInnerHTML={{ __html: errors[0] }} />);
            return;
        } else {
            this.setState({ loading: "Salvando..." });
            let response = await Sig.request('POST', 'planoAcao/savePlanoAcao', { file: JSON.stringify(fileSaved), rascunho: false, id: this.state.id });
            if (response.message) {
                toast.success("Plano de Ação salvo com sucesso!");
                window.parent.postMessage({ type: 'navigate', url: `/planoAcao/detalhar/${response.codigo}` }, '*');
            }
        }
    }

    getDadosPlanoAcao(pageOne = true, pageTwo = true) {

        let allFieldsValid = true;
        let errors = [];
        let fileSaved = {};
        let idVinculosIncorretos = [];

        // Verificar e salva todos os campos obrigatórios estão preenchidos
        if (pageOne || pageTwo) {
            PlanoAcaoHelper.getVerifyVinculoCampos().obrigatory.forEach((campo) => {
                if ( this.state[campo] === "" || (this.state[campo] == null && parseInt(this.state[campo]) === null)) {
                    errors.push('Preencha todos os campos obrigatórios do <strong>Plano de Ação</strong>!');
                    allFieldsValid = false;
                } else {
                    fileSaved[campo] = this.state[campo];
                }
            });
        }

        // Salva os valores dos campos NÃO obrigatórios do plano
        if (pageOne || pageTwo) {
            PlanoAcaoHelper.getVerifyVinculoCampos().optional.forEach((campo) => {
                if (this.state[campo] != null && this.state[campo] != "") {
                    fileSaved[campo] = this.state[campo];
                }
            });
        }

        // Verificar se todos os campos obrigatórios de cada vínculo estão preenchidos
        if (pageTwo) {
            fileSaved["vinculos"] = {};
            this.state.vinculos.forEach((vinculo) => {
                let error = false;

                fileSaved["vinculos"][vinculo.id] = {};
                fileSaved["vinculos"][vinculo.id]["tipo"] = vinculo["tipo"];
                if (vinculo.resultado?.tipo) fileSaved["vinculos"][vinculo.id]["tipoAnalise"] = parseInt(vinculo.resultado.tipo);

                PlanoAcaoHelper.getVerifyVinculoCampos(vinculo.tipo, vinculo.resultado?.tipo).forEach((campo) => {

                    if ((vinculo[campo] === null || vinculo[campo] === "null" || vinculo[campo] === "") && campo != "id_relatorio_recomendacao") {
                        error = vinculo.id;
                        errors.push(`Preencha o campo <strong>${PlanoAcaoHelper.getCampoLabels(campo)}</strong> no <strong>Vínculo</strong>!`);
                        allFieldsValid = false;
                    } else {
                        fileSaved["vinculos"][vinculo.id][campo] = vinculo[campo];
                    }
                });
                if (error || error === 0) idVinculosIncorretos.push(error);
            });
        }

        return { idVinculosIncorretos, allFieldsValid, errors, fileSaved };

    }

    async addVinculo(tipo, id = null, codigo = null, callback = () => { }) {

        // Define um id para o vinculo
        let idNovoVinculo = id ? id : (this.state.vinculos.length == 0 ? 0 : this.state.vinculos[this.state.vinculos.length - 1].id + 1);

        this.setState({ loadingVinculo: idNovoVinculo });

        let novoVinculo = {
            id: idNovoVinculo,
            tipo: tipo,
            ...JSON.parse(JSON.stringify(PlanoAcaoHelper.getVinculoCampos(tipo))),
        }

        if (tipo == GMR) {
            var oldVinculos = this.state.vinculos.filter((item) => item.tipo == GMR);
        }

        this.setState(
            prevState => ({
                vinculos: [...prevState.vinculos, novoVinculo],
                viewSelectVinculo: false
            })
        );

        if (tipo == GMR) {
            let processosGmr = await Sig.request('GET', 'planoAcao/getProcessosTenhoAcesso', { tipo, codigo: codigo ? codigo : JSON.stringify(null) });
            this.setState({ processosGmr });

            let vinculoGmr = this.state.vinculos.find((item) => item.tipo == GMR);

            if(!oldVinculos || oldVinculos.length == 0) {
                let newVinculos = [...this.state.vinculos];
                const index = this.state.vinculos.findIndex((item) => item.id === idNovoVinculo);

                if (processosGmr.length > 0) {
                    newVinculos[index] = {
                        ...newVinculos[index],
                        id_processo: DataHelper.formatSelectedData(processosGmr[0], 'id', 'nome'),
                    };
                }
                    
                this.setState({ vinculos: newVinculos }, () => this.loadGmrPaineis(this.state.vinculos[index]));
            }


            if (vinculoGmr && vinculoGmr.id_processo) {
                const index = this.state.vinculos.findIndex((item) => item.id === idNovoVinculo);
                let newVinculos = [...this.state.vinculos];

                newVinculos[index] = {
                    ...newVinculos[index],
                    id_processo: vinculoGmr.id_processo,
                    gmrPaineisDisponiveis: vinculoGmr.gmrPaineisDisponiveis,
                    id_gmr_painel: vinculoGmr.id_gmr_painel,
                    indicadoresDisponiveis: vinculoGmr.indicadoresDisponiveis,
                    data_inicio: vinculoGmr.data_inicio,
                    data_fim: vinculoGmr.data_fim
                };

                this.setState({ vinculos: newVinculos });
            }

        } else if (tipo == PCO) {
            let processosPesquisas = await Sig.request('GET', 'planoAcao/getProcessosTenhoAcesso', { tipo, codigo: codigo ? codigo : JSON.stringify(null) });
            this.setState({ processosPesquisas });
        } else if (tipo == ANALISE) {
            let processosAnalises = await Sig.request('GET', 'planoAcao/getProcessosTenhoAcesso', { tipo, codigo: codigo ? codigo : JSON.stringify(null) });
            this.setState({ processosAnalises });
        } else if (tipo == AC) {
            let processosGd = await Sig.request('GET', 'planoAcao/getProcessosTenhoAcesso', { tipo, codigo: codigo ? codigo : JSON.stringify(null) });
            this.setState({ processosGd });

            let vinculoAcOriginario = this.state.vinculos[0];

            const index = this.state.vinculos.findIndex((item) => item.id === idNovoVinculo);
            let newVinculos = [...this.state.vinculos];
            newVinculos[index] = { ...newVinculos[index], id_processo: vinculoAcOriginario.id_processo, pilares: vinculoAcOriginario.pilares };
            this.setState({ vinculos: newVinculos });
        } else if (tipo == DIAGNOSTICO) {
            let processosAvaliacao = await Sig.request('GET', 'planoAcao/getProcessosTenhoAcesso', { tipo, codigo: codigo ? codigo : JSON.stringify(null) });
            this.setState({ processosAvaliacao });
        }

        this.setState({ loadingVinculo: false }, () => { callback() });
    }

    removeVinculo(id) {
        let newVinculos = this.state.vinculos.filter((item) => item.id !== id);
        this.setState({ vinculos: newVinculos });
    }

    uploadCallback = async (file) => {
        this.setState({ files: [...this.state.files, file] });
    }

    async loadGmrPaineis(vinculo, callback = () => { }) {

        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadGmrPaineis: true };
        this.setState({ vinculos: newVinculos });
        let gmrPaineisDisponiveis = await PlanoAcaoHelper.getGmrPaineisDisponiveis(newVinculos[index].id_processo.value);

        newVinculos[index] = { ...newVinculos[index], gmrPaineisDisponiveis, loadGmrPaineis: false };
        this.setState({ vinculos: newVinculos }, () => { callback(newVinculos[index]); });

    }

    async getIndicadoresPainel(vinculo, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadIndicadores: true };
        this.setState({ vinculos: newVinculos });

        let indicadoresDisponiveis = await PlanoAcaoHelper.getIndicadoresDisponiveis(newVinculos[index].id_processo.value, newVinculos[index].id_gmr_painel.value);

        newVinculos[index] = { ...newVinculos[index], indicadoresDisponiveis, loadIndicadores: false };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getResultIndicadorPeriodo(vinculo, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];

        if(!moment(newVinculos[index].data_inicio).isValid()) {
            vinculo.data_inicio = null;
        }

        let resultado;
        if (!newVinculos[index].id_indicador || !newVinculos[index].id_indicador.value || !newVinculos[index].id_gmr_painel || !newVinculos[index].id_gmr_painel.value || !newVinculos[index].id_processo || !newVinculos[index].id_processo.value || !newVinculos[index].data_inicio) {
            resultado = {}
        } else {
            resultado = await PlanoAcaoHelper.getResultIndicadorPeriodo(newVinculos[index].id_indicador.value, newVinculos[index].id_gmr_painel.value, newVinculos[index].id_processo.value, newVinculos[index].data_inicio);
        }

        newVinculos[index] = { ...newVinculos[index], resultado };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async loadPergunta(vinculo, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadPergunta: true };
        this.setState({ vinculos: newVinculos });

        let perguntas;

        if (!newVinculos[index].id_processo || !newVinculos[index].id_processo.value) {
            perguntas = {}
        } else {
            perguntas = await PlanoAcaoHelper.getPerguntas(newVinculos[index].id_processo.value);
        }

        newVinculos[index] = { ...newVinculos[index], perguntas, loadPergunta: false };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getResultadoPco(vinculo, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];

        let resultado;
        if (!newVinculos[index].id_processo || !newVinculos[index].id_processo.value || !newVinculos[index].id_pco_pergunta || !newVinculos[index].id_pco_pergunta.value) {
            resultado = {}
        } else {
            resultado = await PlanoAcaoHelper.getResultadoPco(newVinculos[index].id_processo.value, newVinculos[index].id_pco_pergunta.value);
        }

        newVinculos[index] = { ...newVinculos[index], resultado };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getAcoes(vinculo, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadAcao: true };
        this.setState({ vinculos: newVinculos });

        let acoes;
        let resultado;
        let id_processo;

        if (!newVinculos[index].id_processo_analise || !newVinculos[index].id_processo_analise.value) {
            acoes = {};
            resultado = {};
            id_processo = null;
        } else {
            acoes = await PlanoAcaoHelper.getAcoes(newVinculos[index].id_processo_analise.value);
            resultado = await PlanoAcaoHelper.getResultadoAnalise(newVinculos[index].id_processo_analise.value, newVinculos[index]?.data_inicio);
            id_processo = resultado.vinculo?.id_processo ?? null;
        }

        newVinculos[index] = { ...newVinculos[index], acoes, resultado, id_processo, loadAcao: false };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getAssuntoFeedbacks(vinculo) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadFeedbacks: true };
        this.setState({ vinculos: newVinculos });

        let feedback;
        let id_feedback;
        let assunto;

        if (!newVinculos[index].id_assunto) {
            feedback = null;
            id_feedback = null;
            assunto = null;
        } else {
            let response = await Sig.request('GET', 'planoAcao/getFeedbacksVinculo', { id_assunto: newVinculos[index].id_assunto });
            feedback = response.feedback;
            id_feedback = response.id_feedback;
            assunto = response.assunto;
        }

        newVinculos[index] = { ...newVinculos[index], feedback, id_feedback, assunto, loadFeedbacks: false };
        this.setState({ vinculos: newVinculos });
    }

    async getPilarComportamento(id_comportamento) {

        let pilar = await Sig.request('GET', 'planoAcao/getPilaresComportamento', { id_comportamento });
        return pilar.id;
    }

    async getPilaresPeriodoAvaliacao(vinculo, id_colaborador = null, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadComportamentos: true };
        this.setState({ vinculos: newVinculos });

        let pilares;

        if (!newVinculos[index].id_processo || !newVinculos[index].id_processo.value) {
            pilares = [];
        } else {
            id_colaborador = id_colaborador ? id_colaborador : this.state.responsable.value;
            pilares = await Sig.request('GET', 'planoAcao/getPilaresPeriodoAvaliacao', { id_processo: newVinculos[index].id_processo.value, id_colaborador });
        }

        newVinculos[index] = { ...newVinculos[index], pilares, loadComportamentos: false };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getComportamentosPeriodoAvaliacao(vinculo, id_colaborador, callback = () => { }) {

        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadComportamentos: true };
        this.setState({ vinculos: newVinculos });

        let edicao = id_colaborador ? true : false;
        id_colaborador = id_colaborador ? id_colaborador : this.state.responsable.value;

        let comportamentos;

        if (!newVinculos[index].id_processo || !newVinculos[index].id_processo.value) {
            comportamentos = [];
        } else {
            comportamentos = await Sig.request('GET', 'planoAcao/getComportamentosPeriodoAvaliacao', { id_processo: newVinculos[index].id_processo.value, id_pilar: newVinculos[index].id_pilar.value, id_colaborador });
        }

        let id_comportamento = newVinculos[index].id_comportamento ? newVinculos[index].id_comportamento : null;

        if (comportamentos.length === 1 && !edicao) {
            id_comportamento = DataHelper.formatSelectData(comportamentos, 'id', 'nome')[0];
        }

        newVinculos[index] = { ...newVinculos[index], comportamentos, id_comportamento, loadComportamentos: false };
        this.setState({ vinculos: newVinculos }, () => {
            if (comportamentos.length === 1 && !edicao) {
                this.getEstruturaComportamento(newVinculos[index], id_colaborador);
            }
            callback();
        });
    }

    async getEstruturaComportamento(vinculo, id_colaborador, callback = () => { }) {
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadComportamentos: true };
        this.setState({ vinculos: newVinculos });

        id_colaborador = id_colaborador ? id_colaborador : this.state.responsable.value;

        let estruturaComportamento;

        if (!newVinculos[index].id_comportamento || !newVinculos[index].id_comportamento.value || !newVinculos[index].id_processo || !newVinculos[index].id_processo.value) {
            estruturaComportamento = null;
        } else {
            estruturaComportamento = await Sig.request('GET', 'planoAcao/getEstruturaComportamento', { id_processo: newVinculos[index].id_processo.value, id_comportamento: newVinculos[index].id_comportamento.value, id_colaborador });
        }

        newVinculos[index] = { ...newVinculos[index], estruturaComportamento, loadComportamentos: false };
        this.setState({ vinculos: newVinculos }, () => { callback() });
    }

    async getDescricaoAnalise(vinculo) {

        let id_analise = vinculo.id_processo_analise.value;

        let analise = await Sig.request('GET', 'planoAcao/getDescricaoAnalise', { id_analise });

        if (analise.descricao == "" || analise.descricao == null) {
            toast.info('Análise de Problema sem Descrição preenchida.');
            return;
        }

        this.setState({ loadingDescription: true, listLatestDescription: [...this.state.listLatestDescription, this.state.description] }, () => {
            this.setState({ description: analise.descricao, loadingDescription: false, ignoreDescription: true });
        });

    }

    async loadColunasDiagnostico(vinculo, callback = () => { }) {

        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadColunas: true };
        this.setState({ vinculos: newVinculos });

        let colunas = await DiagnosticoHelper.getColunasQuestionarioDiagnosticoAvaliacao(newVinculos[index].id_diagnostico_avaliacao.value);

        newVinculos[index] = { ...newVinculos[index], colunas, loadColunas: false };
        this.setState({ vinculos: newVinculos }, () => { callback(newVinculos[index]); });

    }

    async loadNodes(vinculo, callback = () => { }) {

        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];
        newVinculos[index] = { ...newVinculos[index], loadNodes: true };
        this.setState({ vinculos: newVinculos });

        let nodes = await DiagnosticoHelper.getItensColuna(newVinculos[index].id_coluna.value);

        newVinculos[index] = { ...newVinculos[index], nodes, loadNodes: false };
        this.setState({ vinculos: newVinculos }, () => { callback(newVinculos[index]); });

    }

    async loadResultadoDiagnostico(vinculo, callback = () => { }) {
        
        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
        let newVinculos = [...this.state.vinculos];

        let resultado = await DiagnosticoHelper.getResultadoAvaliacaoItem(newVinculos[index].id_diagnostico_periodo_questionario_node.value, newVinculos[index].id_diagnostico_avaliacao.value);
        let recomendacao = await PlanoAcaoHelper.getRecomendacaoRelatorio(null, newVinculos[index].id_diagnostico_periodo_questionario_node.value, newVinculos[index].id_diagnostico_avaliacao.value);
        let id_relatorio_recomendacao = {value: recomendacao.recomendacao?.relatorioRecomendacao?.id ?? null, label: recomendacao.recomendacao?.relatorioRecomendacao?.descricao ?? '<p>Não existente</p>'};

        newVinculos[index] = { ...newVinculos[index], resultado, id_relatorio_recomendacao };
        this.setState({ vinculos: newVinculos }, () => { callback(newVinculos[index]); });
    }

    backLatestDescription() {
        let listLatestDescription = [...this.state.listLatestDescription];
        let description = listLatestDescription.pop();

        this.setState({ loadingDescription: true }, () => {
            this.setState({ description, listLatestDescription, loadingDescription: false, ignoreDescription: false });
        });
    }

    async getGptDescription() {

        this.setState({ loadingGpt: true });
        let { idVinculosIncorretos, allFieldsValid, errors, fileSaved } = this.getDadosPlanoAcao(true, true);

        if (!allFieldsValid && idVinculosIncorretos.length > 0) {
            idVinculosIncorretos.forEach((id) => {
                delete fileSaved.vinculos[id];
            });
        }

        if (this.state.ignoreDescription) fileSaved.description = null;

        let description = await Sig.request('POST', 'planoAcao/getGptDescription', { file: JSON.stringify(fileSaved), id: this.state.id, codigo: this.state.code });

        this.setState({ loadingDescription: true, listLatestDescription: [...this.state.listLatestDescription, this.state.description] }, () => {
            this.setState({ description: description.descricao, loadingDescription: false, loadingGpt: false }, () => {
                setTimeout(() => {
                    this.setState({ ignoreDescription: true });
                }, 1000);
            });
        });
    }

    getPlaceHolderDescription($tipo) {
        let placeholder = '';
        switch ($tipo) {
            case TIPO_PREVENTIVA:
                placeholder = 'Descreva o Plano de Ação Preventiva com um resumo dos objetivos e das ações planejadas...';
                break;
            case TIPO_CORRETIVA:
                placeholder = 'Descreva o Plano de Ação Corretiva com um resumo dos objetivos e das ações planejadas...';
                break;
            case TIPO_MELHORIA:
                placeholder = 'Descreva o Plano de Ação de Melhoria com um resumo dos objetivos e das ações planejadas...';
                break;
            case TIPO_PDI:
                placeholder = 'Descreva o seu Plano de Desenvolvimento Individual com um resumo dos seus objetivos de desenvolvimento e das ações planejadas...';
                break;
            case TIPO_MARCO:
                placeholder = 'Descreva o Marco de Projeto com um resumo dos objetivos e das principais entregas...';
                break;
            default:
                placeholder = 'Descreva ...';
                break;
        }

        return placeholder;
    }

    // Funções de renderização da página

    renderLoading() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh', ...EssentialStyle.rowFlexCenter }}>
                <DefaultLoader title={typeof this.state.loading === 'string' ? this.state.loading : null} style={{ width: "100%", height: "100%" }} />
            </div>
        )
    }

    renderDatePicker(vinculo, stateProp, callback = () => { }) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', minWidth: '100%', textAlign: 'center', zIndex: 1 }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexCenter,
                minWidth: '100%',
            }}>
                <DatePicker
                    selected={this.state.vinculos.find((item) => item.id === vinculo.id)[stateProp]}
                    type="date"
                    onChange={(date) => {
                        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                        let newVinculos = [...this.state.vinculos];
                        newVinculos[index] = { ...newVinculos[index], [stateProp]: date };
                        this.setState({ vinculos: newVinculos }, callback);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    renderHelp() {
        return (
            <div style={{ position: 'absolute', top: 10, right: 10 }}>
                <DefaultButton 
                style={{ width: 38, height: 38, marginLeft: 8}}
                fontSize={this.state.isSmallScreen ? 14 : "unset"}
                color={Colors.secondaryButton}
                textColor={Colors.dark} 
                link={this.state.modo == 'novo' ? 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#criar' : 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#editar'}/>
            </div>
        );
    }

    renderSteps() {
        let stepContent;

        switch (this.state.step) {
            case 1:
                stepContent = this.renderFormOne();
                break;
            case 2:
                stepContent = this.renderFormTwo();
                break;
            default:
                stepContent = <div>Passo desconhecido</div>;
        }

        return stepContent;
    }

    renderFormOne() {

        let hiddenTipo = this.state.tiposPlano[0].id == 4;

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form style={{ width: '90%', paddingTop: '3rem' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={hiddenTipo ? 12 : 6} xxl={hiddenTipo ? 8 : 4} className="mb-3">
                            <Form.Label>
                                {this.state.type.value <= TIPO_PREVENTIVA ? 'Plano de Ação' : (this.state.type.value == TIPO_PDI ? 'Plano de Desenvolvimento Individual' : 'Marco de Projeto')}*
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={this.state.type.value <= TIPO_PREVENTIVA ? 'Nome do Plano de Ação ...' : (this.state.type.value == TIPO_PDI ? 'Nome do PDI ...' : 'Descrição do Marco de Projeto ...')}
                                value={this.state.name || ""}
                                onChange={(event) => { this.setState({ name: event.target.value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={4} className="mb-3" style={{display: hiddenTipo ? 'none' : ''}}>
                            <Form.Label>Tipo*</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.tiposPlano, 'id', 'nome')}
                                placeholder={'Selecione o tipo de plano'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.type}
                                onChange={(value) => { this.setState({ type: value }) }}
                            />
                        </Form.Group>

                        <AddTag selected={this.state.tags} onChange={(value) => { this.setState({ tags: value }) }} />

                        <Form.Group as={Col} md={6} xxl={4} className="mb-3">
                            <Form.Label>Responsável*</Form.Label>
                            <Select
                                options={this.state.responsables && this.state.responsables.length > 0 ? DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.responsables, 'id', 'nome'), this.state.evaluator?.value) : DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colaboradores, 'id', 'nome'), this.state.evaluator?.value)}
                                placeholder={'Selecione o responsável'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isClearable isSearchable
                                isDisabled={(this.state.type.value === TIPO_PDI)}
                                value={this.state.responsable}
                                onChange={(value) => { this.setState({ responsable: value ? value : null }) }} />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={4} className="mb-3">
                            <Form.Label>Superior / Avaliador</Form.Label>
                            <Select
                                options={DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colaboradores, 'id', 'nome'), this.state.responsable?.value)}
                                placeholder={'Selecione o superior/avaliador'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isClearable isSearchable
                                value={this.state.evaluator}
                                onChange={(value) => { this.setState({ evaluator: value ? value : null }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={4} className="mb-3">
                            <Form.Label>Participantes</Form.Label>
                            <Select
                                options={DataHelper.removeColaborador(DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colaboradores, 'id', 'nome'), this.state.responsable?.value), this.state.evaluator?.value)}
                                placeholder={'Selecione os participantes'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isClearable isSearchable isMulti
                                value={this.state.participants}
                                onChange={(value) => { this.setState({ participants: value }) }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} xxl={3} className="mb-3">
                            <Form.Label>Metodologia de Progresso</Form.Label>
                            <Select
                                options={this.state.tiposProgresso}
                                value={this.state.tiposProgresso.find((item) => item.value === this.state.tipoProgresso)}
                                onChange={(e) => { this.setState({ tipoProgresso: e.value }) }}
                                isDisabled={(this.state.type.value === TIPO_MARCO)}
                                placeholder={'Selecione a Metodologia de Progresso'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                            />
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={'Avançar'}
                                loading={this.state.loading}
                                onClick={() => { this.nextStep() }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    renderFormTwo() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit', paddingTop: '3rem' }}>
                <Form style={{ width: '90%' }}>
                    <Row className="mb-3">
                        {this.renderVinculos()}
                    </Row>


                    {this.renderDescricaoCompleta()}


                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={'Salvar'}
                                loading={this.state.loading}
                                onClick={() => { this.save() }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    renderSelectTipoVinculo() {

        const renderTagVinculo = (nome, tipo) => {
            return (
                <div
                    key={`key-tipo-vinculo-tag-${tipo}`}
                    style={{ padding: '3px 8px', color: Colors.planoAcao.vinculo.color, backgroundColor: Colors.planoAcao.vinculo.backgroundColor[tipo], borderRadius: 5, marginRight: 5, cursor: 'pointer' }}
                    onClick={() => { this.addVinculo(tipo) }}
                >
                    {nome}
                </div>
            )
        }

        let tipoVinculos = this.state.tiposVinculos;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, color: 'white' }}>
                <div style={{ padding: '3px 8px', backgroundColor: Colors.planoAcao.vinculo.backgroundColor[2], borderRadius: 5, marginRight: 5, cursor: 'pointer' }}
                    onClick={() => { this.setState({ viewSelectVinculo: false }) }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>

                {tipoVinculos.map((item) => {
                    return renderTagVinculo(item.nome, item.id);
                })}
            </div>
        )
    }

    renderVinculos() {

        const renderVinculoList = () => {
            return (
                this.state.vinculos.length === 0
                    ?
                    <div style={{ ...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.lightGrey}`, padding: '1rem', width: '100%' }}>
                        <p style={{ margin: 0 }}>
                            Clique em <FontAwesomeIcon icon={faLink} /> <strong>Vínculo</strong> para adicionar novos vínculos ao Plano de Ação.
                        </p>
                    </div>
                    :
                    this.state.vinculos.map((vinculo) => {
                        return (
                            <div style={{ ...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.lightGrey}`, padding: '1rem 0rem', width: '100%' }} key={vinculo.id}>
                                {this.state.loadingVinculo === vinculo.id ?
                                    <p style={{ margin: 0 }}>
                                        <strong>Carregando...</strong>
                                    </p>
                                    :
                                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                                        {this.renderVinculoTipo(vinculo)}
                                        <FontAwesomeIcon icon={faTimesCircle} onClick={() => { this.removeVinculo(vinculo.id) }} style={{ cursor: 'pointer', marginLeft: 5, display: vinculo.disableDelete ? 'none' : '' }} />
                                    </div>
                                }
                            </div>
                        );
                    })
            )
        }

        return (
            <div style={{ ...EssentialStyle.columnCenter }}>
                {renderVinculoList()}
                <div style={{ ...EssentialStyle.rowFlexStart, padding: '1rem 0rem', width: '100%' }}>
                    {this.state.viewSelectVinculo ?
                        this.renderSelectTipoVinculo()
                        :
                        <DefaultButton
                            title={this.state.vinculos.length === 0 ? 'Vincular Plano de Ação com Resultados' : 'Adicionar Vinculo'}
                            color={Colors.success}
                            loading={!(this.state.loadingVinculo == false)}
                            leftIcon={<FontAwesomeIcon icon={faLink} />}
                            onClick={() => { this.setState({ viewSelectVinculo: true }) }}
                        />
                    }
                </div>
            </div>
        )
    }

    renderVinculoTipo(vinculo) {

        const renderVinculoGMR = (vinculo) => {
            let disabledPainel = this.state.vinculos.find((item) => item.id === vinculo.id).id_processo == null || (vinculo.loadGmrPaineis && vinculo.loadGmrPaineis == true);
            let disabledIndicador = this.state.vinculos.find((item) => item.id === vinculo.id).id_gmr_painel == null || this.state.vinculos.find((item) => item.id === vinculo.id).id_processo == null || (vinculo.loadIndicadores && vinculo.loadIndicadores == true);

            return (

                <Form style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={9} xl={5} className="order-md-1 order-xl-1 order-1" >
                            <Form.Label style={{ fontSize: 12 }}>Processo</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.processosGmr, 'id', 'nome')}
                                placeholder={'Processo'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_processo}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_processo: value, id_gmr_painel: null, id_indicador: null, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.loadGmrPaineis(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={3} xl={2} className="order-md-2 order-xl-3 order-4">
                            <Form.Label style={{ fontSize: 12 }}>Período de Referência</Form.Label>
                            <div style={{ ...EssentialStyle.columnCenter }}>
                                {this.renderDatePicker(vinculo, 'data_inicio', () => { this.getResultIndicadorPeriodo(vinculo) })}
                                {vinculo.resultado && vinculo.resultado.performanceRealizadoAll != null && vinculo.resultado.performanceRealizadoAll != "" ?
                                    <CustomTooltip style={{ width: '100%' }} tooltip={
                                        <ul style={{ margin: 0, padding: '5px 10px 5px 20px' }}>
                                            <li><strong>Meta: {vinculo.resultado.metaRealizado}</strong></li>
                                            <li><strong>Realizado: {vinculo.resultado.realizadoRealizado}</strong></li>
                                        </ul>
                                    }>
                                        <div style={{ width: '100%', fontSize: 12, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: vinculo.resultado.performanceRealizadoAllColor, borderRadius: 8, minHeight: 25, ...EssentialStyle.rowFlexCenter }}>
                                            <p style={{ margin: 0 }}><small>Performance: </small>{vinculo.resultado.performanceRealizadoAll}</p>
                                        </div>
                                    </CustomTooltip>
                                    :
                                    <div style={{ width: '100%', fontSize: 12, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: 'grey', borderRadius: 8, minHeight: 25, ...EssentialStyle.rowFlexCenter }}>
                                        <p style={{ margin: 0 }}><small>Performance: </small>--</p>
                                    </div>
                                }
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={9} xl={5} className="order-md-3 order-xl-2 order-2">
                            <Form.Label style={{ fontSize: 12 }}>Painel de Controle</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).gmrPaineisDisponiveis, 'id', 'nome')}
                                placeholder={'Painel'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isDisabled={disabledPainel}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_processo == null ? null : this.state.vinculos.find((item) => item.id === vinculo.id).id_gmr_painel}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_gmr_painel: value, id_indicador: null, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.getIndicadoresPainel(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={3} xl={2} className="order-md-4 order-xl-5 order-5">
                            <Form.Label style={{ fontSize: 12 }}>Periodo de Verificação</Form.Label>
                            <div style={{ ...EssentialStyle.columnCenter }}>
                                {this.renderDatePicker(vinculo, 'data_fim', () => { })}
                                <div style={{ ...EssentialStyle.rowFlexCenter, borderRadius: 8, fontSize: 12, boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, width: '100%' }}>
                                    <CustomTooltip tooltip={"Meta de Performance no Período de Verificação, após a conclusão das atividades do Plano de Ação"}>
                                        <p style={{ margin: '0px 0px 0px 5px' }}>
                                            Meta <FontAwesomeIcon icon={faQuestionCircle} style={{ marginLeft: 0 }} />:
                                        </p>
                                    </CustomTooltip>
                                    <input
                                        type="text"
                                        style={{
                                            marginLeft: 2,
                                            borderTopLeftRadius: 8,
                                            borderBottomLeftRadius: 8,
                                            fontSize: 14,
                                            border: '1px solid grey',
                                            color: Colors.dark,
                                            cursor: 'pointer',
                                            padding: 1,
                                            height: '100%',
                                            width: 'calc(100% - 75px)',
                                            textAlign: 'center',
                                            borderColor: this.state.vinculos.find((item) => item.id === vinculo.id).meta ? 'grey' : 'red' // Muda a cor da borda para vermelho caso a meta não seja preenchida
                                        }}
                                        placeholder="100.00"
                                        value={this.state.vinculos.find((item) => item.id === vinculo.id).meta || ''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            value = value.replace(/[^0-9.]/g, ''); // Remove caracteres não numéricos exceto ponto
                                            const decimalCount = (value.match(/\./g) || []).length;

                                            if (decimalCount <= 1 && (!value.includes('.') || (value.includes('.') && value.split('.')[1].length <= 2))) {
                                                const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                                let newVinculos = [...this.state.vinculos];
                                                newVinculos[index] = { ...newVinculos[index], meta: value };
                                                this.setState({ vinculos: newVinculos });
                                            }
                                        }}
                                        onBlur={(e) => {
                                            let value = parseFloat(e.target.value);
                                            if (!isNaN(value)) {
                                                value = value.toFixed(2); // Formata para duas casas decimais
                                                const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                                let newVinculos = [...this.state.vinculos];
                                                newVinculos[index] = { ...newVinculos[index], meta: value };
                                                this.setState({ vinculos: newVinculos });
                                            }
                                        }}
                                    />
                                    <strong style={{ margin: "0 3px 0 3px" }}>%</strong>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={12} xl={10} className="order-md-5 order-xl-4 order-3">
                            <Form.Label style={{ fontSize: 12 }}>Indicador</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).indicadoresDisponiveis, 'id', 'nome')}
                                placeholder={'Indicador'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_indicador}
                                isDisabled={disabledIndicador}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_indicador: value, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.getResultIndicadorPeriodo(vinculo) });
                                }}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            )
        }

        const renderVinculoPCO = (vinculo) => {

            let disabledPergunta = this.state.vinculos.find((item) => item.id === vinculo.id).id_processo == null || this.state.vinculos.find((item) => item.id === vinculo.id).loadPergunta;

            return (
                <Row style={{ width: 'calc(100% - 21px)' }}>
                    <Form.Group as={Col} md={9} xl={5} className="order-md-1 order-xl-1 order-1">
                        <Form.Label style={{ fontSize: 12 }}>Pesquisa Organizacional</Form.Label>
                        <Select
                            options={DataHelper.formatSelectData(this.state.processosPesquisas, 'id', 'nome')}
                            placeholder={'Selecione a Pesquisa'}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable
                            value={this.state.vinculos.find((item) => item.id === vinculo.id).id_processo}
                            onChange={(value) => {
                                const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                let newVinculos = [...this.state.vinculos];
                                newVinculos[index] = { ...newVinculos[index], id_processo: value ? value : null, id_pco_pergunta: null, resultado: null };
                                this.setState({ vinculos: newVinculos }, () => { this.loadPergunta(vinculo) });
                            }}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={3} xl={2} className="order-md-2 order-xl-3 order-3">
                        <Form.Label style={{ fontSize: 12 }}>Resultados</Form.Label>
                        {vinculo.resultado && vinculo.resultado.resultado != null ?
                            <div style={{ fontSize: 12, height: 36, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: vinculo.resultado.cor ? vinculo.resultado.cor : 'red', borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                {vinculo.resultado.resultado}%
                            </div>
                            :
                            <div style={{ fontSize: 12, height: 36, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: 'grey', borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                Indisponível
                            </div>
                        }
                    </Form.Group>

                    <Form.Group as={Col} md={12} xl={5} className="order-md-3 order-xl-2 order-2">
                        <Form.Label style={{ fontSize: 12 }}>Pergunta</Form.Label>
                        <Select
                            options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).perguntas, 'id', 'descricao')}
                            placeholder={'Selecione a Pergunta'}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isDisabled={disabledPergunta}
                            isSearchable
                            value={this.state.vinculos.find((item) => item.id === vinculo.id).id_pco_pergunta}
                            onChange={(value) => {
                                const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                let newVinculos = [...this.state.vinculos];
                                newVinculos[index] = { ...newVinculos[index], id_pco_pergunta: value ? value : null, resultado: null };
                                this.setState({ vinculos: newVinculos }, () => { this.getResultadoPco(vinculo) });
                            }}
                        />
                    </Form.Group>
                </Row>
            )
        }

        const renderVinculoAnalise = (vinculo) => {

            let disableAcao = vinculo.id_processo_analise == null || vinculo?.loadAcao == true;
            let tipo = vinculo.id_processo_analise != null && vinculo.resultado != null ? parseInt(vinculo.resultado.tipo) : null;

            const buttonImportDescription = (span = 1) => {
                return (
                    !disableAcao && (
                        <Form.Group as={Col} md={span}>
                            <Form.Label style={{ fontSize: 12 }}></Form.Label>
                            <div style={{ ...EssentialStyle.rowFlexEnd, paddingTop: 9, color: "white" }}>
                                <CustomTooltip tooltip={"Importar descrição gerada na Análise de Problema"}>
                                    <div
                                        style={{ ...EssentialStyle.columnCenter, width: 38, height: 38, padding: 3, backgroundColor: SessionHelper.getColor(), borderRadius: "50%" }} className="hoverMouse"
                                        onClick={() => { this.getDescricaoAnalise(vinculo) }}
                                    >
                                        <FontAwesomeIcon icon={faClipboard} />
                                    </div>
                                </CustomTooltip>
                            </div>
                        </Form.Group>
                    )
                )
            }

            return (
                <div style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={4}>
                            <Form.Label style={{ fontSize: 12 }}>Analise de Problema</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.processosAnalises, 'id', 'nome')}
                                placeholder={'Selecione a Análise'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_processo_analise}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_processo_analise: value, id_analise_problema_acao: null, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.getAcoes(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={4}>
                            <Form.Label style={{ fontSize: 12 }}>Ação</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).acoes, 'id', 'descricao')}
                                placeholder={'Selecione a Análise'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                isDisabled={disableAcao}
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_analise_problema_acao}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_analise_problema_acao: value };
                                    this.setState({ vinculos: newVinculos }, () => { });
                                }}
                            />
                        </Form.Group>

                        {(tipo == 1 || tipo == 2) && (

                            <>
                                <Form.Group as={Col} md={2} style={{ paddingLeft: 0 }}>
                                    <Form.Label style={{ fontSize: 12 }}>Período de Referência</Form.Label>
                                    <div style={{ ...EssentialStyle.columnCenter }}>
                                        {this.renderDatePicker(vinculo, 'data_inicio', () => { this.getAcoes(vinculo) })}
                                        {vinculo?.resultado?.resultado?.performanceRealizadoAll != null && vinculo.resultado.resultado.performanceRealizadoAll != "" ?
                                            <CustomTooltip style={{ width: '100%' }} tooltip={
                                                <ul style={{ margin: 0, padding: '5px 10px 5px 20px' }}>
                                                    <li><strong>Meta: {vinculo.resultado.resultado.metaRealizado}</strong></li>
                                                    <li><strong>Realizado: {vinculo.resultado.resultado.realizadoRealizado}</strong></li>
                                                </ul>
                                            }>
                                                <div style={{ width: '100%', fontSize: 12, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: vinculo.resultado.resultado.performanceRealizadoAllColor, borderRadius: 8, minHeight: 25, ...EssentialStyle.rowFlexCenter }}>
                                                    <p style={{ margin: 0 }}><small>Performance: </small>{vinculo.resultado.resultado.performanceRealizadoAll}</p>
                                                </div>
                                            </CustomTooltip>
                                            :
                                            <div style={{ width: '100%', fontSize: 12, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: 'grey', borderRadius: 8, minHeight: 25, ...EssentialStyle.rowFlexCenter }}>
                                                <p style={{ margin: 0 }}><small>Performance: </small>--</p>
                                            </div>
                                        }
                                    </div>
                                </Form.Group>

                                <Form.Group as={Col} md={2} style={{ paddingLeft: 0 }}>
                                    <Form.Label style={{ fontSize: 12 }}>Periodo de Verificação</Form.Label>
                                    <div style={{ ...EssentialStyle.columnCenter }}>
                                        {this.renderDatePicker(vinculo, 'data_fim', () => { })}
                                        <div style={{ ...EssentialStyle.rowFlexCenter, borderRadius: 8, fontSize: 12, boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, width: '100%' }}>
                                            <CustomTooltip tooltip={"Meta de Performance no Período de Verificação, após a conclusão das atividades do Plano de Ação"}>
                                                <p style={{ margin: '0px 0px 0px 5px' }}>
                                                    Meta <FontAwesomeIcon icon={faQuestionCircle} style={{ marginLeft: 0 }} />:
                                                </p>
                                            </CustomTooltip>
                                            <input
                                                type="text"
                                                style={{
                                                    marginLeft: 2,
                                                    borderTopLeftRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    fontSize: 14,
                                                    border: '1px solid grey',
                                                    color: Colors.dark,
                                                    cursor: 'pointer',
                                                    padding: 1,
                                                    height: '100%',
                                                    width: 'calc(100% - 75px)',
                                                    textAlign: 'center',
                                                    borderColor: this.state.vinculos.find((item) => item.id === vinculo.id).meta ? 'grey' : 'red' // Muda a cor da borda para vermelho caso a meta não seja preenchida
                                                }}
                                                placeholder="100.00"
                                                value={this.state.vinculos.find((item) => item.id === vinculo.id).meta || ''}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^0-9.]/g, ''); // Remove caracteres não numéricos exceto ponto
                                                    const decimalCount = (value.match(/\./g) || []).length;

                                                    if (decimalCount <= 1 && (!value.includes('.') || (value.includes('.') && value.split('.')[1].length <= 2))) {
                                                        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                                        let newVinculos = [...this.state.vinculos];
                                                        newVinculos[index] = { ...newVinculos[index], meta: value };
                                                        this.setState({ vinculos: newVinculos });
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    let value = parseFloat(e.target.value);
                                                    if (!isNaN(value)) {
                                                        value = value.toFixed(2); // Formata para duas casas decimais
                                                        const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                                        let newVinculos = [...this.state.vinculos];
                                                        newVinculos[index] = { ...newVinculos[index], meta: value };
                                                        this.setState({ vinculos: newVinculos });
                                                    }
                                                }}
                                            />
                                            <strong style={{ margin: "0 3px 0 3px" }}>%</strong>
                                        </div>
                                    </div>
                                </Form.Group>
                            </>
                        )}

                        {tipo == 3 && (
                            <Form.Group as={Col} md={4}>
                                <Form.Label style={{ fontSize: 12 }}>Resultados</Form.Label>
                                {vinculo.resultado && vinculo.resultado.resultado != null ?
                                    <div style={{ fontSize: 12, height: 25, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: vinculo.resultado.resultado.cor ? vinculo.resultado.resultado.cor : 'grey', borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                        {vinculo.resultado.resultado.resultado}%
                                    </div>
                                    :
                                    <div style={{ fontSize: 12, height: 25, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: 'grey', borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                        Indisponível
                                    </div>
                                }
                            </Form.Group>
                        )}

                        {tipo != 1 && tipo != 2 && tipo != 3 && tipo != null && (
                            buttonImportDescription(4)
                        )}
                    </Row>

                    {(tipo == 1 || tipo == 2) && (
                        <Row>
                            <Form.Group as={Col} md={3}>
                                <Form.Label style={{ fontSize: 12 }}>Processo GMR</Form.Label>
                                <div>{vinculo.resultado.vinculo.processo}</div>
                            </Form.Group>

                            <Form.Group as={Col} md={4}>
                                <Form.Label style={{ fontSize: 12 }}>Painel</Form.Label>
                                <div>{vinculo.resultado.vinculo.painel}</div>
                            </Form.Group>

                            <Form.Group as={Col} md={4}>
                                <Form.Label style={{ fontSize: 12 }}>Indicador</Form.Label>
                                <div>{vinculo.resultado.vinculo.indicador}</div>
                            </Form.Group>

                            {buttonImportDescription(1)}
                        </Row>
                    )}

                    {tipo == 3 && (
                        <Row>
                            <Form.Group as={Col} md={5}>
                                <Form.Label style={{ fontSize: 12 }}>Pesquisa Organizacional</Form.Label>
                                <div>{vinculo.resultado.vinculo.processo}</div>
                            </Form.Group>

                            <Form.Group as={Col} md={6}>
                                <Form.Label style={{ fontSize: 12 }}>Pergunta</Form.Label>
                                <div>{vinculo.resultado.vinculo.pergunta}</div>
                            </Form.Group>

                            {buttonImportDescription(1)}
                        </Row>
                    )}

                </div>
            )
        }

        const renderVinculoFeedbacks = (vinculo) => {

            return (
                <div style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={12} xl={6}>
                            <Form.Label style={{ fontSize: 12 }}>Vínculo com Feedback Contínuo</Form.Label>
                            <div>{vinculo.feedback}</div>
                        </Form.Group>

                        <Form.Group as={Col} md={12} xl={6}>
                            <Form.Label style={{ fontSize: 12 }}>Assunto</Form.Label>
                            <div>{vinculo.assunto}</div>
                        </Form.Group>
                    </Row>
                </div>
            )
        }

        const renderVinculoAC = (vinculo) => {

            vinculo = this.state.vinculos.find((item) => item.id === vinculo.id);

            return (
                <div style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={9} className="order-md-1 order-xl-1 order-1">
                            <Form.Label style={{ fontSize: 12 }}>Avaliação de Competências</Form.Label>
                            <div>{vinculo.id_processo.label}</div>
                        </Form.Group>

                        {vinculo && vinculo.estruturaComportamento && vinculo.estruturaComportamento.resultado && vinculo.estruturaComportamento.resultado.media &&
                            <Form.Group as={Col} md={3} className="order-md-2 order-xl-2 order-2">
                                <Form.Label style={{ fontSize: 12, fontSize: "bold" }}>Resultado</Form.Label>
                                <div style={{ fontSize: 12, height: 36, padding: '0px 10px 0px 10px', marginLeft: -0, color: Colors.white, backgroundColor: vinculo.estruturaComportamento.resultado.color ? vinculo.estruturaComportamento.resultado.color : 'red', borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                    {vinculo.estruturaComportamento.resultado.media}%
                                </div>
                            </Form.Group>
                        }
                    </Row>

                    <Row>
                        <Form.Group as={Col} md={3}>
                            <Form.Label style={{ fontSize: 12 }}>Pilar</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).pilares, 'id', 'nome')}
                                placeholder={'Selecione o pilar'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_pilar}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_pilar: value };
                                    this.setState({ vinculos: newVinculos }, () => { this.getComportamentosPeriodoAvaliacao(vinculo, null) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6}>
                            <Form.Label style={{ fontSize: 12 }}>Comportamento</Form.Label>
                            {
                                this.state.vinculos.find((item) => item.id === vinculo.id).comportamentos && this.state.vinculos.find((item) => item.id === vinculo.id).comportamentos.length == 1 && this.state.vinculos.find((item) => item.id === vinculo.id).id_comportamento ? (
                                    <div>{this.state.vinculos.find((item) => item.id === vinculo.id).id_comportamento.label}</div>
                                ) : (
                                    <Select
                                        options={DataHelper.formatSelectData(this.state.vinculos.find((item) => item.id === vinculo.id).comportamentos, 'id', 'nome')}
                                        placeholder={'Selecione o comportamento'}
                                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                        isSearchable
                                        isDisabled={this.state.vinculos.find((item) => item.id === vinculo.id).id_pilar == null}
                                        value={this.state.vinculos.find((item) => item.id === vinculo.id).id_comportamento}
                                        onChange={(value) => {
                                            const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                            let newVinculos = [...this.state.vinculos];
                                            newVinculos[index] = { ...newVinculos[index], id_comportamento: value };
                                            this.setState({ vinculos: newVinculos }, () => { this.getEstruturaComportamento(vinculo, null) });
                                        }}
                                    />
                                )
                            }
                        </Form.Group>

                        {vinculo && vinculo.estruturaComportamento && vinculo.estruturaComportamento.resultado && vinculo.estruturaComportamento.resultado.media &&
                            <Form.Group as={Col} md={3}>
                                <Form.Label style={{ fontSize: 12 }}>Competência</Form.Label>
                                <div>{vinculo.estruturaComportamento.competencia}</div>
                            </Form.Group>
                        }
                    </Row>
                </div>
            )
        }

        const renderVinculoMarco = (vinculo) => {
            return (
                <div style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={12} xl={12} className="mb-1" >
                            <div style={{ ...EssentialStyle.rowFlexCenter, color: Colors.white, backgroundColor: SessionHelper.getColor(), borderRadius: 5, fontSize: 14}}>
                                <strong>Vínculo com Projeto</strong>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={12} xl={6} className="mb-1" >
                            <Form.Label style={{ fontSize: 12 }}>Projeto</Form.Label>
                            <div>{vinculo.id_pe_projeto.label}</div>
                        </Form.Group>
                        <Form.Group as={Col} md={12} xl={6}>
                            <Form.Label style={{ fontSize: 12 }}>Marco</Form.Label>
                            <div>{vinculo.id_pe_projeto_marco.label}</div>
                        </Form.Group>
                    </Row>
                </div>
            )
        }

        const renderVinculoDiagnostico = (vinculo) => {
            let disabledAvaliacao = vinculo?.disableAlter ? true : false;
            let disabledColuna = this.state.vinculos.find((item) => item.id === vinculo.id).id_diagnostico_avaliacao == null || vinculo.loadColunas || vinculo.disableAlter;
            let disabledNodes = this.state.vinculos.find((item) => item.id === vinculo.id).id_coluna == null || this.state.vinculos.find((item) => item.id === vinculo.id).id_diagnostico_avaliacao == null || vinculo.loadColunas || vinculo.loadNodes || vinculo.disableAlter;

            return (
                <div style={{ width: 'calc(100% - 21px)' }}>
                    <Row>
                        <Form.Group as={Col} md={9} xxl={4} className="order-mb-1 order-xxl-1">
                            <Form.Label style={{ fontSize: 12 }}>Diagnóstico</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.processosAvaliacao, 'id', 'nome')}
                                placeholder={'Selecione o Processo / Período / Avaliação'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                isDisabled={disabledAvaliacao}
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_diagnostico_avaliacao}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_diagnostico_avaliacao: value, id_coluna: null, id_diagnostico_periodo_questionario_node: null, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.loadColunasDiagnostico(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={3} xxl={2} className="order-mb-2 order-xxl-4">
                            <Form.Label style={{ fontSize: 12 }}>Resultado</Form.Label>
                            <div style={{ fontWeight: 'bold', height: 36, padding: '0px 10px', marginLeft: 0, color: Colors.white, backgroundColor: vinculo.resultado?.cor ?? Colors.homePage.lightGrey, borderRadius: 8, ...EssentialStyle.rowFlexCenter }}>
                                <div>{vinculo.resultado?.valor ?? '--'}%</div>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md={4} xxl={2} className="order-mb-3 order-xxl-2">
                            <Form.Label style={{ fontSize: 12 }}>Coluna do Questionário</Form.Label>
                            <Select
                                isDisabled={disabledColuna}
                                options={DataHelper.formatSelectData( this.state.vinculos.find((item) => item.id === vinculo.id).colunas, 'id', 'descricao')}
                                placeholder={'Selecione a Coluna'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_coluna}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_coluna: value, id_diagnostico_periodo_questionario_node: null, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.loadNodes(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={8} xxl={4} className="order-mb-4 order-xxl-3">
                            <Form.Label style={{ fontSize: 12 }}>Item</Form.Label>
                            <Select
                                isDisabled={disabledNodes}
                                options={DataHelper.formatSelectData( this.state.vinculos.find((item) => item.id === vinculo.id).nodes, 'id', 'descricao')}
                                placeholder={'Selecione o item'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                value={this.state.vinculos.find((item) => item.id === vinculo.id).id_diagnostico_periodo_questionario_node}
                                onChange={(value) => {
                                    const index = this.state.vinculos.findIndex((item) => item.id === vinculo.id);
                                    let newVinculos = [...this.state.vinculos];
                                    newVinculos[index] = { ...newVinculos[index], id_diagnostico_periodo_questionario_node: value, resultado: null };
                                    this.setState({ vinculos: newVinculos }, () => { this.loadResultadoDiagnostico(vinculo) });
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className="order-mb-2 order-xxl-4">
                            <Form.Label style={{ fontSize: 12 }}>Recomendação</Form.Label>
                            <div dangerouslySetInnerHTML={{ __html: vinculo?.id_relatorio_recomendacao?.label || "<p>Não existente</p>"}} />
                        </Form.Group>

                    </Row>
                </div>
            )
        }

        switch (parseInt(vinculo.tipo)) {
            case GMR:
                return renderVinculoGMR(vinculo);
            case PCO:
                return renderVinculoPCO(vinculo);
            case AC:
                return renderVinculoAC(vinculo);
            case ANALISE:
                return renderVinculoAnalise(vinculo);
            case FEEDBACK:
                return renderVinculoFeedbacks(vinculo);
            case MARCO:
                return renderVinculoMarco(vinculo);
            case DIAGNOSTICO:
                return renderVinculoDiagnostico(vinculo);
            default:
                return <div>Erro ao renderizar campos do vinculo</div>;
        }
    }

    renderDescricaoCompleta() {

        const renderCausaNaoConformidade = () => {
            return (
                <>
                    <Form.Group as={Col} md={6} xxl={3}>
                        <Form.Label>Não Conformidade</Form.Label>
                        <TextEditor
                            defaultValue={this.state.nonCompliance || ""}
                            onChange={(value) => { this.setState({ nonCompliance: value }) }}
                            placeholder={'Descreva o problema identificado ou o desvio em relação à meta...'}
                            minHeight={60}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6} xxl={3}>
                        <Form.Label>Causa Raiz</Form.Label>
                        <TextEditor
                            defaultValue={this.state.rootCause || ""}
                            onChange={(value) => { this.setState({ rootCause: value }) }}
                            placeholder={'Descreva o motivo pelo qual o problema ou desvio acontece...'}
                            minHeight={60}
                        />
                    </Form.Group>
                </>
            )
        }

        const renderDescricao = () => {

            let hasLatestDescription = this.state.listLatestDescription.length > 0;
            let placeholder = this.getPlaceHolderDescription(this.state.type.value);

            return (
                <Row className="gx-2">

                    {this.state.type?.value <= TIPO_PREVENTIVA && renderCausaNaoConformidade()}

                    <Col>
                        <Form.Group>
                            <div style={EssentialStyle.rowSpaceBetween}>
                                <Form.Label>
                                    Descrição
                                    {
                                        hasLatestDescription && <FontAwesomeIcon
                                            icon={faUndo}
                                            className={`backDescriptionIcon hoverMouse`}
                                            style={{ marginLeft: 10, borderRadius: '50%' }}
                                            onClick={() => {
                                                const iconElement = document.querySelector('.backDescriptionIcon');
                                                iconElement.classList.add('rotateClockwise');
                                                setTimeout(() => {
                                                    iconElement.classList.remove('rotateClockwise');
                                                    this.backLatestDescription();
                                                }, 1000);

                                            }}
                                        />
                                    }
                                </Form.Label>
                                <FontAwesomeIcon
                                    icon={faMagic}
                                    style={{ marginRight: 10, padding: 3, borderRadius: '50%' }}
                                    className={this.state.loadingGpt ? 'rotating' : 'hoverMouse'}
                                    onClick={() => {
                                        !this.state.loadingGpt && this.getGptDescription();
                                    }}
                                />
                            </div>

                            {this.state.loadingDescription ? (
                                <DefaultLoader />
                            ) : (
                                <TextEditor
                                    defaultValue={this.state.description || ""}
                                    onChange={(value) => { this.setState({ description: value, ignoreDescription: false }) }}
                                    placeholder={placeholder}
                                    minHeight={60}
                                />
                            )}
                        </Form.Group>
                    </Col>

                    {/* Tamanho fixo de 150px */}
                    <Col xs={12} md="auto" style={{ minWidth: '15rem' }}>
                        <Form.Group>
                            <Form.Label>Anexar Arquivos</Form.Label>
                            <UploadFiles uploadCallback={this.uploadCallback} id={this.state.id} action={"planoAcao/planoAcao"} limit={10} />
                        </Form.Group>
                    </Col>
                </Row>
            );
        }

        return (
            <>
                {renderDescricao()}
            </>
        )
    }

    render() {

        if (this.state.loading) return this.renderLoading();

        return (
            <div style={{ 
                    minWidth: '100%', 
                    minHeight: '100vh',
                    marginBottom: this.state.isSmallScreen ? '6rem' : 0,
                }}
            >
                {this.renderStepBack()}
                {this.renderHelp()}
                {this.renderSteps()}
            </div>
        );
    }
}
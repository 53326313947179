import React from "react";
import Constants from "../../../../../constants/Api";

import './DetalhamentoObjetivoResumo.css';
import IndicadoresObjetivo from "./IndicadoresObjetivo/IndicadoresObjetivo";
import ProjetosObjetivo from "./ProjetosObjetivo/ProjetosObjetivo";

const gateway = Constants.getSigEndPoint();

export default class DetalhamentoObjetivoResumo extends React.Component {
    state = {
        loading: true,
        typeView: this.props.typeView,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.typeView !== this.props.typeView) {
            this.setState({typeView: this.props.typeView})
        }
    }

    renderContent() {
        
        const renderIndicadores = () => {
            return (
                <IndicadoresObjetivo
                    key={"indicadores-objetivo-" + this.props.id}
                    idObjetivo={this.props.id} 
                    isSmallScreen={this.state.isSmallScreen}
                    permissao_indicador={this.props.permissao_indicador}
                />
            )
        }

        const renderProjetos = () => {
            return (
                <ProjetosObjetivo
                    key={"projetos-objetivo-" + this.props.id}
                    idObjetivo={this.props.id} 
                    isSmallScreen={this.state.isSmallScreen}
                    permissao_projeto={this.props.permissao_projeto}
                />
            )
        }

        return (
            <div className="bodyDetalhamentoObjetivo">
                {this.state.typeView === 'indicadores' ? renderIndicadores() : renderProjetos()}
            </div>
        )
    }

    render() {

        return (
            <div className={"boxDetalhamentoObjetivo"} style={{borderTopLeftRadius: this.props.index == 0 ? '0px' : '' }}>
                {this.renderContent()}
            </div>
        );
    }
}